
// モジュールを読込.
import { Vue, Options, Prop, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import CalendarManager from "packs/models/CalendarManager"
import axios from "axios"

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class TutorialModal extends Vue {
    /**
     * Astagの新規作成、名前変更
     * クイック投票作成時の会議名の入力に利用します.
     */
    @Prop() // quick_vote
    tutorialType: string

    // data
    rm = RoomManager
    cm = CalendarManager
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    validName = true

    astagName = ``
    saving = false
    notice = Notice
    type = ``

    tutorialDic = {
        quick_vote: {
            title: `候補を選択`,
            description: `ドラッグして候補を選択したら、送信ボタンで完了です！`,
            imageUrl: `/assets/mov/vote_tutorial_v.png`,
        },
    }

    openModal() {
        Logger(`TutorialModal#openModal `)
        if (this.tutorialType) {
            gtagPage(`#/チュートリアルモーダル/${this.tutorialType}`)
        }
    }

    get getTitle() {
        if (!this.tutorialType) return
        let dic = this.tutorialDic[this.tutorialType]
        return dic.title
    }

    get getDescription() {
        if (!this.tutorialType) return

        let dic = this.tutorialDic[this.tutorialType]
        return dic.description
    }

    get getImageUrl() {
        if (!this.tutorialType) return

        let dic = this.tutorialDic[this.tutorialType]
        return dic.imageUrl
    }

    public closeModal() {
        this.$vfm.close(`TutorialModal`)
    }

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    public clickOK() {
        if (!this.rm.confirmed_quick_vote_tutorial) {
            // 次回以降表示しないようにします。
            axios.put(`${Util.prefixUrl}/confirmed_quick_vote_tutorial`).then(res => {
                if (res.data.result == `ok`) {
                    this.rm.confirmed_quick_vote_tutorial = true
                }
                this.closeModal()
            })
        } else {
            this.closeModal()
        }
    }
}
