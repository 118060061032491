import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("img", {
    src: _ctx.$getAssetImageUrl(_ctx.getColor()),
    width: _ctx.width ? _ctx.width : 12,
    height: _ctx.width ? _ctx.width * 1.07 : 12,
    class: "pointer"
  }, null, 8, _hoisted_1)), [
    [_directive_tooltip, 
            _ctx.tooltipText
                ? `${_ctx.tooltipText} ${
                      _ctx.detailUrl ? `\n\n<a class='fw600 whiteColor' target='_blank' href='${_ctx.detailUrl}'>詳しくはこちら</a>` : ``
                  }`
                : null
        ]
  ])
}