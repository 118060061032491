
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"

import AdPromBusinessModal from "packs/components/advertise/AdPromBusinessModal.vue"
import UserIconMenu from "packs/components/icons/UserIconMenu.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

import RoomManager from "packs/models/RoomManager"
import ModalInnerInquiry from "./modals/ModalInnerInquiry.vue"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import { gtagClick } from "packs/GoogleTagManager"

@Options({ components: { ModalInnerInquiry, AdPromBusinessModal, UserIconMenu, IconHelp } })
export default class ChukaiSidebarMenu extends Vue {
    @Prop()
    currentPage

    @Prop()
    selectedTab

    @Prop() // スマホの場合の選択タブを教えてもらいます。 rooms/fix
    selectedTabName
    //  = location.href;

    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    // type: string;

    selectCategoryTab(tabName: string) {
        this.$emit(`selectCategoryTab`, tabName)
    }

    // @Watch("location", { deep: true })
    // changeLocation() {
    //     this.currentUrl = location.href;
    // }
    public goAvailableSchedule() {
        this.rm.resetCurrentInfo()
        this.$router.push(`/available_schedule`)
    }

    public goReports() {
        Const.paidWithPath(this.rm, `/reports`, "AdPromBusinessModal", this)
    }

    goAssignForm() {
        this.$router.push(`/assign_forms`)
    }

    public async mounted() {
        // this.type = this.selectedTab;
        // targets.addEventListener("click", this.clickAvailableTimeEvent, false);
    }

    public destroyed() {}

    public imgRooms() {
        if (this.selectedTab == `categoryProgress`) return "/assets/navi/pages_sp_select.png"
        return "/assets/navi/pages_sp.png"
    }

    public imgFix() {
        if (this.selectedTab == `categoryFixed`) return "/assets/navi/enter_sp_select.png"
        return "/assets/navi/enter_sp.png"
    }

    public fadeOutInquiryModal() {}

    public clickSpSearchBox() {
        this.$emit("clickSpSearchBox")
    }

    showAccountPage() {
        if (Util.isBlank(this.rm.userInfo)) return
        const uf = this.rm.userInfo
        if (uf.user_group_role == 100) return true
        const cate = `account_settings`
        if (
            UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`) ||
            UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`) ||
            UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`) ||
            UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)
        ) {
            return true
        }

        return false
    }

    showAstagsPage() {
        if (Const.chukaiFreePlan(this.rm)) return false
        return true
    }

    showAssignFormPage() {
        if (Util.isBlank(this.rm.userInfo)) return
        if (Const.chukaiFreePlan(this.rm)) return false

        return true
    }

    showReportsPage() {
        if (Util.isBlank(this.rm.userInfo)) return
        if (Const.chukaiFreePlan(this.rm)) return false

        const uf = this.rm.userInfo
        if (uf.user_group_role == 100) return true

        const cate = `reports`
        if (
            UserPermissionsOption.permitDisplay(uf, cate, `reports`) ||
            UserPermissionsOption.permitDisplay(uf, cate, `progresses`)
        ) {
            return true
        }

        return false
    }

    goHelpCenter() {
        gtagClick(`go HelpCenter from Left Menu`, `${this.rm.userInfo.email}`)
        window.open(`https://help.waaq.jp`)
    }

    goClientAvailableSchedule() {
        this.rm.resetCurrentInfo()
        this.$router.push(`/client/available_schedule`)
    }

    showPublicRoom() {
        if (Const.chukaiFreePlan(this.rm)) return false
        return true
    }
}
