
// モジュールを読込.
import { Options, Prop, Vue, Provide, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "../../utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, getAssetImageUrl } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ModalInnerHowToUseCal from "packs/pages/link/modals/ModalInnerHowToUseCal.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import RoomManager from "../../models/RoomManager"
import Const from "packs/utils/Const"

@Options({
    components: {
        ModalInnerHowToUseCal,
        VueFinalModal,
    },
})
export default class WhatStrictCal extends Vue {
    // data:

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Const = Const

    public openWhatStrictCal() {
        Logger(`調整カレンダーとはを開きます。`)
        gtagClick(`調整カレンダーとはボタン`, ``)
        this.$vfm.open("WhatStrictCalModal")
    }

    public hideWhatStrictCal() {
        this.$vfm.close("WhatStrictCalModal")
    }
}
