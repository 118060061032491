import SimplePeer from "simple-peer"
import ActionCable from "actioncable"
import { reactive } from "vue"
import { Logger } from "packs/common"
import * as process from "process"
// import * as vad from "voice-activity-detection"
import axios from "axios"

window.process = process

interface WebRTCManager {
    peer?: SimplePeer.Instance | null
    stream?: MediaStream | null
    cable: any | null
    roomId: string | null
    mediaRecorder?: MediaRecorder
    audioChunks: Blob[]
    audioContext: any
    receivedChunks: any[]
    totalChunks: number | null
    wholeAudio: MediaRecorder

    createNew(cable: any, roomId: string): void
    startCall(initiator: boolean): Promise<boolean>
    handleIncomingSignal(signal: any): void
    endCall(): void
    setupVAD(stream: MediaStream): void
    // sendVadStatusToServer(status: string): void
    getCSRFToken(): string
    startRecording(): void
    stopRecording(): Promise<void>
    sendAudioToServer(audioBlob: Blob): Promise<void>
    sendFullAudioToServer(audioBlob: Blob): Promise<void>
}
let _WebRTCManager: WebRTCManager = reactive({
    peer: null,
    stream: null,
    cable: null,
    roomId: null,
    mediaRecorder: null,
    audioChunks: [],
    audioContext: null,
    receivedChunks: [],
    totalChunks: null,
    wholeAudio: null,
    remoteStream: null as MediaStream | null,
    remoteAudioSource: null as MediaStreamAudioSourceNode | null,

    // constructor(private cable: any, private roomId: string) {}
    createNew(cable: any, roomId: string) {
        Logger(`WebRTCManager.createNew`)
        // this.cable = cable
        this.cable = cable.subscriptions.create(
            { channel: "WebrtcChannel", room_id: roomId },
            {
                received: (data: any) => {
                    if (data.signal) {
                        this.handleIncomingSignal(data.signal)
                    }
                    if (data.audio_chunk) {
                        this.processAudioChunk(data)
                    }
                },
            }
        )
        this.roomId = roomId

        // AudioContextとdestinationを初期化
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()
        this.destination = this.audioContext.createMediaStreamDestination()
    },
    startTalk() {
        Logger(`WebRTCManager.startTalk`)
        axios
            .post(
                "/api/call/talk/start_talk",
                { room_id: this.roomId },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": this.getCSRFToken(),
                    },
                }
            )
            .then(response => {
                console.log("start_talk response:", response)
                // 全体の音声録音を開始します.
                // this.startWholeAudio()
            })
            .catch(error => console.error("start_talk error:", error))
    },
    startWholeAudio() {
        Logger(`WebRTCManager.startWholeAudio`)

        // 全体の音声録音を開始します.
        let mimeType = "audio/webm"
        if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
            mimeType = "audio/webm;codecs=opus"
        } else if (MediaRecorder.isTypeSupported("audio/ogg;codecs=opus")) {
            mimeType = "audio/ogg;codecs=opus"
        }

        try {
            const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()
            const destination = audioContext.createMediaStreamDestination()

            // ローカルストリームを追加
            if (this.stream) {
                const localSource = audioContext.createMediaStreamSource(this.stream)
                localSource.connect(destination)
            }

            // リモートストリームを追加（存在する場合）
            // if (this.remoteStream) {
            //     this.remoteAudioSource = audioContext.createMediaStreamSource(this.remoteStream)
            //     this.remoteAudioSource.connect(destination)
            // }

            this.wholeAudio = new MediaRecorder(destination.stream, {
                mimeType: mimeType,
                audioBitsPerSecond: 128000,
            })

            this.wholeAudio.ondataavailable = event => {
                if (event.data.size > 0) {
                    this.audioChunks.push(event.data)
                }
            }

            this.wholeAudio.start(100) // 100msごとにデータを取得

            Logger(`wholeAudio録音開始`)
        } catch (e) {
            console.error("MediaRecorder の初期化に失敗しました:", e)
        }
    },

    async startCall(initiator: boolean) {
        Logger(`WebRTCManager.startCall`)
        if (!this.cable || !this.roomId) {
            console.error("Cable or roomId is not initialized. Please call init() first.")
            return false
        }

        if (this.peer) {
            this.peer.destroy()
            this.peer = null
        }

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            this.peer = new SimplePeer({
                initiator,
                stream: this.stream,
                trickle: true,
                config: {
                    iceServers: [
                        { urls: "stun:stun.l.google.com:19302" },
                        { urls: "stun:stun1.l.google.com:19302" },
                        { urls: "stun:stun2.l.google.com:19302" },
                        { urls: "stun:stun3.l.google.com:19302" },
                        { urls: "stun:stun4.l.google.com:19302" },
                    ],
                },
            })

            this.peer.on("signal", data => {
                // this.cable.perform("send_signal", {
                //     signal: data,
                //     room_id: this.roomId,
                // })
                if (this.cable && typeof this.cable.send === "function") {
                    this.cable.send({ signal: data, room_id: this.roomId })
                } else {
                    console.error("Cable is not properly initialized or doesn't have a send method")
                }
            })

            this.peer.on("connect", () => {
                Logger("Peer connection established")
            })

            this.peer.on("error", err => {
                console.error("Peer connection error:", err)
            })

            this.peer.on("close", () => {
                Logger("Peer connection closed")
                this.peer = null
            })

            this.peer.on("stream", (stream: MediaStream) => {
                Logger("Received remote stream")
                // this.remoteStream = stream

                // 既存のオーディオ要素があれば停止して削除
                const existingAudio = document.getElementById("remote-audio") as HTMLAudioElement
                if (existingAudio) {
                    existingAudio.pause()
                    existingAudio.remove()
                }

                // リモートストリームをwholeAudioに追加
                // if (this.audioContext && this.destination) {
                //     this.remoteAudioSource = this.audioContext.createMediaStreamSource(stream)
                //     this.remoteAudioSource.connect(this.destination)
                // }

                // 新しいオーディオ要素を作成
                const audio = new Audio()
                audio.id = "remote-audio"
                audio.srcObject = stream
                audio.autoplay = true
                // audio.playsInline = true // iOSデバイスでの自動再生のため
                document.body.appendChild(audio)

                // オプション：音量調整
                audio.volume = 1.0 // 0.0から1.0の間で設定

                // 再生開始を確認
                audio.onplaying = () => {
                    Logger("リモートオーディオの再生を開始しました")
                }

                // エラーハンドリング
                audio.onerror = e => {
                    console.error("オーディオ再生エラー:", e)
                }

                // 再生の準備ができたら開始
                audio.oncanplay = () => {
                    audio.play().catch(e => console.error("自動再生に失敗しました:", e))
                }
            })

            if (!initiator) {
                // 応答側の場合、オファーを待つ
                await new Promise<void>(resolve => {
                    this.peer.once("signal", () => resolve())
                })
            }

            return true
        } catch (error) {
            console.error("Failed to start call:", error)
            return false
        }
    },

    handleIncomingSignal(signal: any) {
        Logger(`WebRTCManager.handleIncomingSignal`)
        if (this.peer) {
            try {
                if (signal.type === "offer" && this.peer.signalingState !== "stable") {
                    Logger("Ignoring offer in non-stable state")
                    return
                }
                if (signal.type === "answer" && this.peer.signalingState !== "have-local-offer") {
                    Logger("Ignoring answer in non-offer state")
                    return
                }
                this.peer.signal(signal)
            } catch (error) {
                console.error("Error handling incoming signal:", error)
            }
            // if (signal.type === "offer") {
            //     this.peer.signal(signal)
            // } else if (signal.type === "answer") {
            //     if (this.peer.signalingState === "have-local-offer") {
            //         this.peer.signal(signal)
            //     } else {
            //         console.warn("Received answer in unexpected state:", this.peer.signalingState)
            //     }
            // } else {
            //     this.peer.signal(signal)
            // }
        } else {
            console.error("Peer is not initialized")
        }
    },

    endCall() {
        Logger(`WebRTCManager.endCall`)
        if (this.peer) {
            this.peer.destroy()
            this.peer = null
        }
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop())
            this.stream = null
        }

        // 全体の録音を停止し、ファイルをアップロード
        Logger(`WebRTCManager.endCall wholeAudio.state:${this.wholeAudio?.state}`)
        if (this.wholeAudio) {
            const wholeAudioChunks = []
            this.wholeAudio.ondataavailable = event => {
                if (event.data.size > 0) {
                    wholeAudioChunks.push(event.data)
                }
            }

            this.wholeAudio.onstop = () => {
                const audioBlob = new Blob(wholeAudioChunks, { type: this.wholeAudio.mimeType })
                this.sendFullAudioToServer(audioBlob)
                this.audioChunks = [] // チャンクをリセット
            }
            this.wholeAudio.stop()
        }
    },

    async sendFullAudioToServer(audioBlob: Blob) {
        const audioFile = new File([audioBlob], "full_audio.webm", { type: audioBlob.type })
        const formData = new FormData()
        formData.append("audio", audioFile)
        formData.append("room_id", this.roomId || "")

        try {
            const response = await axios.post("/api/call/talk/end_call", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRF-Token": this.getCSRFToken(),
                },
            })
            console.log("全体の音声ファイルをサーバーに送信:", response.data)
        } catch (error) {
            console.error("全体の音声ファイルの送信エラー:", error)
            if (axios.isAxiosError(error) && error.response) {
                console.error("サーバーからのエラーメッセージ:", error.response.data)
            }
        }
    },

    setupVAD(stream) {
        const AudioContext = window.AudioContext || (window as any).webkitAudioContext
        const audioContext = new AudioContext()
        const source = audioContext.createMediaStreamSource(stream)
        const analyser = audioContext.createAnalyser()
        analyser.fftSize = 2048
        const bufferLength = analyser.fftSize
        const dataArray = new Uint8Array(bufferLength)

        // ソースをアナライザーに接続
        source.connect(analyser)

        let isSpeaking = false
        let silenceTimer = 0
        const silenceThreshold = 500 // ミリ秒 silenceが500ms以上続く場合、停止します.
        let recordingTimer = 0
        const minRecordingTime = 1000 // ミリ秒
        const preBufferTime = 2000 // ミリ秒
        let audioBuffer = []
        let isBuffering = true

        let isSending = false
        let pendingAudio = null

        let speakingTimer = 0
        const minSpeakingTime = 200 // ミリ秒

        let isRecording = false

        const checkVoiceActivity = () => {
            analyser.getByteTimeDomainData(dataArray)

            // RMS（Root Mean Square）を計算
            let sum = 0
            for (let i = 0; i < bufferLength; i++) {
                const value = dataArray[i] / 128 - 1 // 正規化
                sum += value * value
            }
            const rms = Math.sqrt(sum / bufferLength)

            const rmsThreshold = 0.01 // 音声検出のしきい値
            console.log(`WebRTCManager.setupVAD rms:${rms}, rmsThreshold:${rmsThreshold}`)

            if (rms > rmsThreshold) {
                // 音声が検出された場合
                if (!isSpeaking) {
                    isSpeaking = true
                    silenceTimer = 0
                    speakingTimer = 0
                    console.log("WebRTCManager.setupVAD 音声検出開始")
                    // 発話ステータスを更新
                    const vadStatus = document.getElementById("vad-status")
                    if (vadStatus) vadStatus.textContent = "発話中"
                    // 録音開始
                    if (!isRecording) {
                        this.startRecording()
                        isRecording = true
                    }
                } else {
                    speakingTimer += 16
                    console.log(`WebRTCManager.setupVAD speakingTimer: ${speakingTimer}`)
                }
            } else {
                // 無音状態
                if (isSpeaking) {
                    silenceTimer += 16
                    if (silenceTimer > silenceThreshold && speakingTimer > minSpeakingTime) {
                        isSpeaking = false
                        console.log("WebRTCManager.setupVAD 音声検出終了")
                        // 発話ステータスを更新
                        const vadStatus = document.getElementById("vad-status")
                        if (vadStatus) vadStatus.textContent = "無音"
                        // 録音停止
                        if (isRecording) {
                            console.log("WebRTCManager.setupVAD 録音停止")
                            this.stopRecording().then(() => {
                                if (this.audioChunks.length > 0) {
                                    console.log("WebRTCManager.setupVAD 録音停止後の処理")
                                    const audioBlob = new Blob(this.audioChunks, { type: this.mediaRecorder.mimeType })
                                    this.sendAudioToServer(audioBlob)
                                    this.audioChunks = [] // チャンクをリセット
                                    isRecording = false
                                }
                            })
                        }
                        silenceTimer = 0
                        speakingTimer = 0
                    }
                }
            }

            requestAnimationFrame(checkVoiceActivity)
        }

        checkVoiceActivity()
    },
    // sendVadStatusToServer(status: string) {
    //     const params = { status: status, room_id: this.roomId }
    //     axios
    //         .post("/api/call/talk/vad_events", params, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "X-CSRF-Token": this.getCSRFToken(),
    //             },
    //         })
    //         .then(response => {
    //             console.log("VADステータスをサーバーに送信:", response.data)
    //         })
    //         .catch(error => console.error("エラー:", error))
    // },
    getCSRFToken() {
        const meta = document.querySelector('meta[name="csrf-token"]')
        return meta ? meta.getAttribute("content") : ""
    },

    startRecording(preBuffer = []) {
        if (!this.stream) return

        this.audioChunks = []
        let mimeType = "audio/webm"
        if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
            mimeType = "audio/webm;codecs=opus"
        } else if (MediaRecorder.isTypeSupported("audio/ogg;codecs=opus")) {
            mimeType = "audio/ogg;codecs=opus"
        }

        try {
            this.mediaRecorder = new MediaRecorder(this.stream, {
                mimeType: mimeType,
                audioBitsPerSecond: 128000, // ビットレートを指定
            })
            this.mediaRecorder.addEventListener("dataavailable", event => {
                if (event.data.size > 0) {
                    this.audioChunks.push(event.data)
                }
            })

            // プリバッファの内容を追加
            if (preBuffer.length > 0) {
                const preBufferStream = new MediaStream(preBuffer)
                const preBufferRecorder = new MediaRecorder(preBufferStream, { mimeType: mimeType })
                preBufferRecorder.addEventListener("dataavailable", event => {
                    if (event.data.size > 0) {
                        this.audioChunks.push(event.data)
                    }
                })
                preBufferRecorder.start()
                setTimeout(() => {
                    preBufferRecorder.stop()
                    this.mediaRecorder.start(100)
                }, 100)
            } else {
                this.mediaRecorder.start(100)
            }

            Logger(`録音開始: ${mimeType}`)
        } catch (e) {
            console.error("MediaRecorder の初期化に失敗しました:", e)
        }
    },

    async stopRecording(): Promise<void> {
        return new Promise(resolve => {
            if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
                this.mediaRecorder.addEventListener("stop", () => resolve(), { once: true })
                this.mediaRecorder.stop()
            } else {
                resolve()
            }
        })
    },

    async sendAudioToServer(audioBlob: Blob) {
        const audioFile = new File([audioBlob], "audio.webm", { type: audioBlob.type })
        const formData = new FormData()
        formData.append("audio", audioFile)
        formData.append("room_id", this.roomId || "")

        try {
            const response = await axios.post("/api/call/talk/audio_upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRF-Token": this.getCSRFToken(),
                },
            })
            console.log("音声ファイルをサーバーに送信:", response.data)
        } catch (error) {
            console.error("音声ファイルの送信エラー:", error)
            if (axios.isAxiosError(error) && error.response) {
                console.error("サーバーからのエラーメッセージ:", error.response.data)
            }
        }
    },

    async processAudioChunk(data) {
        const { audio_chunk, chunk_index, total_chunks, format } = data

        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()
        }

        if (this.totalChunks === null) {
            this.totalChunks = total_chunks
            this.receivedChunks = new Array(total_chunks)
        }

        const decodedChunk = atob(audio_chunk)
        const arrayBuffer = new ArrayBuffer(decodedChunk.length)
        const uintArray = new Uint8Array(arrayBuffer)
        for (let i = 0; i < decodedChunk.length; i++) {
            uintArray[i] = decodedChunk.charCodeAt(i)
        }

        this.receivedChunks[chunk_index] = uintArray

        if (this.receivedChunks.filter(chunk => chunk !== undefined).length === this.totalChunks) {
            const totalLength = this.receivedChunks.reduce((acc, chunk) => acc + (chunk ? chunk.length : 0), 0)
            const completeAudioArray = new Uint8Array(totalLength)
            let offset = 0
            for (const chunk of this.receivedChunks) {
                if (chunk) {
                    completeAudioArray.set(chunk, offset)
                    offset += chunk.length
                }
            }

            try {
                const decodedAudio = await this.audioContext.decodeAudioData(completeAudioArray.buffer)
                this.playAudioBuffer(decodedAudio)
            } catch (error) {
                console.error("音声データのデコードエラー:", error)
            }

            // リセット
            this.receivedChunks = []
            this.totalChunks = null
        }
    },

    playAudioBuffer(buffer: AudioBuffer) {
        if (this.audioContext) {
            let sourceNode = this.audioContext.createBufferSource()
            sourceNode.buffer = buffer
            let gainNode = this.audioContext.createGain()

            sourceNode.connect(gainNode)
            gainNode.connect(this.audioContext.destination)

            sourceNode.start()
            console.log("音声の再生を開始しました")

            // 再生が終了したらゲインを元に戻す
            sourceNode.onended = () => {
                gainNode.disconnect()
            }
        }
    },
})

export default _WebRTCManager
