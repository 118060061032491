
import Util from "packs/utils/Util"
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({})
export default class IconHelp extends Vue {
    @Prop() // アイコンの大きさ
    width: number

    @Prop() // 表示するテキスト
    tooltipText: string

    @Prop() // 詳しくはこちらのURL
    detailUrl: string

    @Prop() // grey / white
    color: string

    $getAssetImageUrl!: (path: string) => string

    getColor() {
        if (Util.isBlank(this.color)) return `/assets/home/help_icon_dgrey.png`
        if (this.color == `grey`) return `/assets/home/help_icon_dgrey.png`
        if (this.color == `white`) return `/assets/home/help_icon_white.gif`
        return `/assets/home/help_icon_dgrey.png`
    }
}
