
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { copyToClipboard, Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import { DateTime } from "luxon"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import Appointment from "packs/models/Appointment"

@Options({
    components: {},
})
export default class AppointmentCardView extends Vue {
    @Prop()
    appo: Appointment
    @Prop()
    appoindex: number

    // data:
    showTip = false
    notice = Notice

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    beforeAppo: Appointment = null

    appoStatus = Appointment.typeAddNew
    doesChangeAppo: boolean
    Util = Util

    // @Emit("update")
    // updatePossibleDates() {}

    // @Watch("rm.currentAppointment", { deep: true })
    // public updateAppo() {
    //     this.appo = this.rm.currentAppointment;
    //     Logger(`update appo: ${Util.output(this.appo)}`);
    //     // this.getURLFromAddress(this.appo.location, `320x320`);
    // }

    public fixedTimeFormat() {
        if (!this.appo) return ``

        if (this.appo.status == 10 && this.appo.start_time) {
            return this.appo.jp_format
        } else if (this.appo.status == -1) {
            return this.appo.jp_format
        }
        return ``
    }

    public createdAtText(): string {
        Logger(`createdAtText: appo: ${Util.output(this.appo)} created_at: ${this.appo.created_at}`)
        if (!this.appo || !this.appo.created_at) return ``
        let createdAt = DateTime.fromSeconds(this.appo.created_at)
        return createdAt.toFormat("MM/dd HH:mm")
    }

    /**
     * Googleから取得してくるか、電話会議の場合画像を はめます。
     */
    public getURLFromAddress(address, size: string) {
        const onlines = Util.getOnlines()

        if (this.isBlank(address)) {
            return ""
        }

        // Skypeやslackなど
        if (onlines.find(e => address.toLocaleLowerCase().includes(e))) {
            return "/assets/icons/call_square_grey.png"
        }

        if (this.isSP) {
            // const width = window.outerWidth;
            size = `320x160`
        } else {
            // const height = $(".appoLocationImage").innerHeight();
            // if (height) {
            //     size = `160x${height}`;
            // }
            // else {
            //     return;
            // }
        }

        // Logger(`size: ${size}`);

        // GoogleMapに問い合わせるURLに変換.
        return Util.gmapUrl(address, size)
    }

    public isBlank(str: string): boolean {
        if (!str || str === ``) {
            return true
        }
        return false
    }

    /**
     * 現在のアポを編集します。
     */
    // public editScheduleInfo() {
    //     Logger("edit!");
    //     if (this.appo.status == 10) {
    //         // 確定したあとの変更手続き.（メモのみ変更可）
    //         Logger(`fixした予定の変更`);
    //         this.appoStatus = Appointment.typeEditAfterFixed;
    //     } else {
    //         this.appoStatus = Appointment.typeEdit;
    //     }

    //     this.beforeAppo = Object.assign({}, this.rm.currentAppointment);

    //     this.$vfm.open("EditAppointmentModal");
    //     // gtag("event", "open", {
    //     //     event_category: "どこで",
    //     //     event_label: "どこで"
    //     // });
    // }

    // gtag用
    // public addNewAppo() {
    //     gtagClick(`新規でアポ作成`, `MessageTopRight`);
    //     this.addNewAppointment();
    // }

    /**
     * 新規でアポを作成します。
     */
    // public addNewAppointment() {
    //     this.appoStatus = Appointment.typeAddNew;
    //     Logger(`新規でアポを作成します。`);
    //     this.beforeAppo = Object.assign({}, this.rm.currentAppointment);
    //     this.$vfm.open("EditAppointmentModal");
    // }

    // public hideEditAppointmentModal(didChange: boolean, appo: Appointment) {
    //     this.doesChangeAppo = didChange;
    //     this.$vfm.close("EditAppointmentModal");
    // }

    // public fadeOutEditAppointmentModal() {
    //     if (!this.doesChangeAppo) {
    //         Logger(`変更内容を戻します。}`);
    //         this.rm.currentAppointment = this.beforeAppo;
    //     }
    //     /**
    //      * 所要時間の変更・チューカイ出席可否の変更があった場合、possibleDatesの更新が必要です。
    //      */
    //     if (
    //         this.rm.currentAppointment.required_owner_participation !=
    //             this.beforeAppo.required_owner_participation ||
    //         this.rm.currentAppointment.duration != this.beforeAppo.duration
    //     ) {
    //         this.updatePossibleDates();
    //     }
    //     this.doesChangeAppo = false;
    // }

    public copyScheduleInfo() {
        const title = $(".appointmentTitle").text().trim()
        const info = $(".appointmentInfo").text().replace(/\s+/g, " ")
        copyToClipboard(`${title}\n${info}`)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`クリップボードにコピー`, `${title}${info}`)
    }

    public hideTooltip(e) {
        this.showTip = false
    }
}
