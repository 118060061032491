
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import ListLoader from "packs/components/loader/ListLoader.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import EditGroupTagModal from "packs/pages/link/settings/EditGroupTagModal.vue"
import Switcher from "packs/components/forms/Switcher.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import DateTag from "packs/models/DateTag"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import GroupTag from "packs/models/GroupTag"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        SelectTag,
        TextfieldTag,
        UserSettingsHeader,
        MdTextfieldTag,
        ListLoader,
        ChipButton,
        EditGroupTagModal,
        Switcher,
    },
})
export default class UserSettingsGroupTags extends Vue {
    // data:

    notice = Notice

    UserPermissionsOption = UserPermissionsOption
    rm = RoomManager
    Util = Util
    selectedTab = `rooms`
    truncate = truncate

    isSP = isMobile(window.navigator).phone
    astag: AvailableScheduleTag = null
    today = DateTime.local().setZone("Asia/Tokyo")
    selectedCategory = `groupTags`

    Const = Const

    newTag: GroupTag = null
    groupTags: GroupTag[] = null
    editTag: GroupTag = null

    loading = false

    showSuggests = false
    myTabGroups = []
    searchText = null
    searchedTags = []
    editGrtag: GroupTag = null

    @Ref()
    header

    created() {
        let urlParams = FormUtil.getParams()
        this.newTag = GroupTag.createDefault()

        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkPermission()
                this.getGroupTags()
            })
        } else {
            this.checkPermission()
            this.getGroupTags()
        }

        if (Util.isPresent(urlParams) && Util.isPresent(urlParams.tab)) {
            let tabName = urlParams.tab
            if (tabName == `group_tag`) {
                this.selectedCategory = `groupTags`
            }
        }
    }

    selectCategory(name: string) {
        this.selectedCategory = name
    }

    getGroupTags() {
        if (!this.groupTags) {
            this.myTabGroups = this.rm.tabGroupTags || []
            this.rm.getGroupTags().then(tags => {
                this.groupTags = tags || []

                if (this.searchText) {
                    this.inputSearchText(this.searchText)
                } else {
                    this.searchedTags = [...this.groupTags]
                }
            })
        } else {
            this.searchedTags = [...this.groupTags]
            this.myTabGroups = this.rm.tabGroupTags || []
        }
    }

    mounted() {
        gtagPage("#/settings/rooms")
    }

    inputTagName(e) {
        this.newTag.name = e
    }

    inputDescription(e) {
        this.newTag.description = e
    }

    inputColor(e) {
        this.newTag.color = e
    }

    inputEditTagName(e) {
        this.editTag.name = e
    }

    inputEditTagDescription(e) {
        this.editTag.description = e
    }

    inputEditTagColor(e) {
        this.editTag.color = e
    }

    deleteGroupTag(grtag: GroupTag) {
        Logger(`${funcName()}`)
        if (this.loading) return
        this.loading = true

        GroupTag.deleteTag(grtag).then(data => {
            this.loading = false
            if (data) {
                // 一覧から削除.
                this.groupTags = this.groupTags.filter(t => t.id != data.id)
                this.rm.groupTags = this.groupTags
                this.searchedTags = this.groupTags

                // 自分のタブから削除.
                this.myTabGroups = (this.rm.tabGroupTags || []).filter(t => t.id != data.id)
                this.rm.tabGroupTags = this.myTabGroups
            }
        })
    }

    editGroupTag(grtag: GroupTag) {
        Logger(`${funcName()} grtag:${grtag.id}`)
        this.editTag = GroupTag.copy(grtag)
        // this.editTag = grtag
    }

    cancelEditTag() {
        this.editTag = null
    }

    changeShowType() {
        if (this.editTag.show_type === `pinned_all`) {
            this.editTag.show_type = `normal`
        } else {
            this.editTag.show_type = `pinned_all`
            this.editTag.pinned_index_num = this.editTag.pinned_index_num || 1
        }
    }

    inputPinnedIndexNum(e) {
        this.editTag.pinned_index_num = e
    }

    updateEditTag() {
        if (this.loading) return
        this.loading = true

        GroupTag.upsertGroupTag(this.editTag).then(data => {
            this.loading = false
            if (data) {
                for (let grt of this.groupTags) {
                    if (grt.id == data.id) {
                        grt.name = data.name
                        grt.description = data.description
                        grt.color = data.color
                        grt.show_type = data.show_type
                        grt.pinned_index_num = data.pinned_index_num
                    }
                }
                this.editTag = null
                this.searchedTags = [...this.groupTags]
            }
        })
    }

    addNewTag() {
        if (this.loading) return
        this.loading = true

        GroupTag.upsertGroupTag(this.newTag).then(data => {
            this.loading = false
            if (data) {
                this.groupTags.push(data)
                this.newTag = GroupTag.createDefault()
                this.rm.groupTags = this.groupTags
                this.searchedTags = [...this.groupTags]
            }
        })
    }

    /**
     * ユーザーがアドレス帳から削除されたら表示を消します。
     */
    public deleteUser(user) {
        Logger(`削除されたユーザー: ${Util.output(user)}`)
        this.rm.address_book.contact_list = this.rm.address_book.contact_list.filter(u => u.user_id != user.user_id)
    }

    public openUploadModal() {
        this.$vfm.open("UploadModal")
    }

    public uploadedFile() {
        this.$vfm.close("UploadModal")
    }

    checkPermission() {
        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `user_settings`, `user_settings_address_book`)) {
            this.$router.push("/settings/user")
        }
    }

    inputSearchText(e) {
        this.searchText = e
        let grTags = this.groupTags.filter(gt => gt.name.includes(this.searchText))
        this.searchedTags = grTags
    }

    selectGroupTag(grTag: GroupTag) {
        Logger(`${funcName()} grTag:${grTag.name}`)
        let selected = this.myTabGroups.find(grt => grt.id == grTag.id)
        if (selected) {
            //
            Notice.message = `${grTag.name}のグループタグはすでに追加されています。`
        } else {
            this.myTabGroups.push(grTag)
        }
    }

    updateMyTabGroups() {
        if (this.loading) return
        this.loading = true

        GroupTag.createTabGroupTags(this.myTabGroups).then(data => {
            this.loading = false
            if (data) {
                this.myTabGroups = data
            }
        })
    }

    clickSearchField() {
        this.showSuggests = true
    }

    blurSearchField() {
        sleep(200).then(_ => {
            this.showSuggests = false
        })
    }

    // clickSelectGrtag(grTag: GroupTag) {
    //     Logger(`${funcName()} grTag:${Util.output(grTag)}`)

    //     this.$vfm.open(`EditGroupTagModal`)
    // }

    // editEnd(grTag: GroupTag) {
    //     Logger(`${funcName()} grTag:${Util.output(grTag)}`)
    // }

    deleteSelectGrtag(grTag: GroupTag) {
        this.myTabGroups = this.myTabGroups.filter(gt => gt.id != grTag.id)
    }
}
