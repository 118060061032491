import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["onMousedown", "onMouseover", "onMouseup"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "fs11 pointer ml5"
}
const _hoisted_8 = {
  key: 0,
  class: "with_white_bg rad3 dgrey padw2 fs10 pointer posAb",
  style: {"top":"-1px","right":"20px"}
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { key: 5 }
const _hoisted_14 = { key: 6 }
const _hoisted_15 = ["src", "onClick"]
const _hoisted_16 = ["src", "onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "arial fs14" }
const _hoisted_19 = {
  key: 1,
  class: "arial fs12 ml4",
  style: {"color":"rgb(204, 87, 61)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlackBoxEventMenu = _resolveComponent("BlackBoxEventMenu")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_OneEventContent = _resolveComponent("OneEventContent")!
  const _component_BlackBoxEvent = _resolveComponent("BlackBoxEvent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`dailyLine w100 posRe ${_ctx.isDisabled}`),
    id: `clickAddEvent${_ctx.id}`,
    style: _normalizeStyle({
            height: `${_ctx.CELL_HEIGHT * 4 * 24 + 250}px`,
            border: `1px solid #ececec`,
        })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Util.selectHours, (grid) => {
      return (_openBlock(), _createElementBlock("div", {
        key: grid,
        id: `grid${_ctx.id}${grid}`,
        class: "posAb w100 hourGridLine",
        style: _normalizeStyle({
                top: `${_ctx.CELL_HEIGHT * 4 * grid + _ctx.alldayHeight}px`,
            })
      }, null, 12, _hoisted_2))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Util.selectHours, (grid) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `grid30${grid}`,
        id: `grid30${_ctx.id}${grid}`,
        class: "posAb w100 min30GridLine",
        style: _normalizeStyle({
                top: `${_ctx.CELL_HEIGHT * 4 * grid + _ctx.alldayHeight + _ctx.CELL_HEIGHT * 2}px`,
            })
      }, null, 12, _hoisted_3))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SelectUtil.hoursPer15, (grid) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${grid}${_ctx.id}`,
        class: _normalizeClass(`posAb w100 ${_ctx.isDisabled == `` && _ctx.type != `top` ? `copy` : ``} ${
                _ctx.selecting && _ctx.selectCells.includes(grid) ? `lgreyBg` : ``
            } ${!_ctx.selecting && _ctx.cm.mouseOverCell == `${grid}${_ctx.id}` ? `llgreyBg` : ``}`),
        style: _normalizeStyle({
                height: `${_ctx.CELL_HEIGHT}px`,
                top: `${_ctx.CELL_HEIGHT * grid + _ctx.alldayHeight}px`,
            }),
        onMousedown: ($event: any) => (_ctx.startCell(grid, _ctx.id)),
        onMouseover: ($event: any) => (_ctx.mouseoverCell(grid, _ctx.id)),
        onMouseup: ($event: any) => (_ctx.endCell(grid, _ctx.id))
      }, null, 46, _hoisted_4))
    }), 128)),
    (_ctx.avzones && _ctx.showAvailableZones())
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avzones, (avzone) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${_ctx.cm.astag.systemUpdatedAt}${avzone.systemUpdatedAt}${avzone.start_hour}${_ctx.id}${_ctx.wday}`
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(`posAb orangeBg availableEvent w100 rad5 availableBox ofh op1 t${avzone.systemUpdatedAt} i${_ctx.id} noClick`),
                style: _normalizeStyle({
                        height: `${(avzone.duration / 15) * _ctx.CELL_HEIGHT}px`,
                        top: `${(avzone.start_hour * 4 + avzone.start_min / 15) * _ctx.CELL_HEIGHT + _ctx.alldayHeight}px`,
                        left: `0`,
                    })
              }, null, 6)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.selecting)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle({
                top: _ctx.selectCells[0] * _ctx.CELL_HEIGHT + _ctx.alldayHeight - 22 + `px`,
                left: `1%`,
                background: `rgba(0,0,0,0.6)`,
            }),
          class: _normalizeClass(`posAb rad5 displayDuration fs11 fw600 pad5 whiteColor z5`)
        }, _toDisplayString(_ctx.selectCells.length * 15) + "分 ", 5))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "posFi with_white_bg z8",
      style: _normalizeStyle({
                top: `220px`,
                height: `${_ctx.alldayHeight}px`,
                width: `130px`,
                borderBottom: _ctx.alldayHeight == 0 ? `` : `1px solid #ddd`,
            })
    }, [
      (_ctx.Util.isPresent(_ctx.alldayEvents))
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alldayEvents, (ev, evindex) => {
              return (_openBlock(), _createElementBlock("div", { key: evindex }, [
                _createElementVNode("div", {
                  class: _normalizeClass(`${
                            ev.is_match_available_keyword ? `orangeBg` : ev.is_match_ignore_keyword ? `dblueBg` : `blackbrownBg`
                        } rad4 fs11 whiteColor pad3 ${_ctx.selecting ? `noClick` : `pointer`}`),
                  style: {"height":"20px"}
                }, [
                  _createVNode(_component_Dropdown, {
                    ref_for: true,
                    ref: "dropdown",
                    "not-close-elements": _ctx.notCloseLi
                  }, {
                    dropdown: _withCtx(() => [
                      _createVNode(_component_BlackBoxEventMenu, {
                        ref_for: true,
                        ref: "BlackBoxEventMenu",
                        ev: ev,
                        onAddEvent: _ctx.addEvent,
                        onChangeAvailableEvent: _ctx.changeAvailableEvent,
                        onChangeIgnoreEvent: _ctx.changeIgnoreEvent,
                        onChangeMaxBookingsNum: _ctx.changeMaxBookingsNum
                      }, null, 8, ["ev", "onAddEvent", "onChangeAvailableEvent", "onChangeIgnoreEvent", "onChangeMaxBookingsNum"])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(`dropdown-toggle whiteColor availableBox pointer t${ev.systemUpdatedAt}`)
                      }, [
                        (ev.title)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                              _createTextVNode(_toDisplayString(_ctx.truncate(ev.title, 7)) + " ", 1),
                              (ev.allday)
                                ? (_openBlock(), _createElementBlock("label", _hoisted_8, "終日"))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        (
                                        ev.allday &&
                                        !(
                                            ev.is_match_available_keyword ||
                                            ev.is_match_ignore_keyword ||
                                            ev.ignore_allday ||
                                            _ctx.cm.astag.is_only_use_optional_schedules
                                        )
                                    )
                          ? _withDirectives((_openBlock(), _createElementBlock("img", {
                              key: 1,
                              src: _ctx.$getAssetImageUrl(`/assets/icons/bikkuri_yellow.png`),
                              width: "16",
                              class: "posAb",
                              style: {"margin-top":"-2px","top":"0","right":"0"},
                              onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseoverAlert && _ctx.mouseoverAlert(...args)))
                            }, null, 40, _hoisted_9)), [
                              [_directive_tooltip, `終日予定が表示したい日程候補をブロックしている可能性があります`]
                            ])
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["not-close-elements"])
                ], 2)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 4),
    (_ctx.optionalAddEvents && !_ctx.isSelfMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionalAddEvents, (ev, evindex) => {
            return (_openBlock(), _createBlock(_component_OneEventContent, {
              key: `${evindex}${ev.systemUpdatedAt}${ev.start_time}${_ctx.date}${ev.type}${_ctx.optionalEvents.length}${ev.is_force}${ev.isSelecting}`,
              ev: ev,
              evindex: evindex,
              date: _ctx.date,
              class: _normalizeClass(`${_ctx.selecting ? `noClick` : ``}`),
              classes: `${_ctx.type == `top` ? (ev.isSelecting ? `z5 op4` : `z3 op4 `) : ``}`,
              onDeleteOptionalEvent: _ctx.deleteOptionalEvent,
              onAddEvent: _ctx.addEvent,
              onStartDrag: _ctx.startDrag,
              onEndDrag: _ctx.endDrag
            }, null, 8, ["ev", "evindex", "date", "class", "classes", "onDeleteOptionalEvent", "onAddEvent", "onStartDrag", "onEndDrag"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.Util.isPresent(_ctx.events))
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (ev, evindex) => {
            return (_openBlock(), _createBlock(_component_BlackBoxEvent, {
              key: `${evindex}${ev.start_time}${ev.systemUpdatedAt}${ev.short_format}${ev.title}`,
              class: _normalizeClass(`${_ctx.selecting ? `noClick` : ``}`),
              ev: ev,
              alldayHeight: _ctx.alldayHeight,
              fromType: _ctx.type,
              onChangeAvailableEvent: _ctx.changeAvailableEvent,
              onChangeIgnoreEvent: _ctx.changeIgnoreEvent,
              onShowEventDetail: _ctx.showEventDetail,
              onChangeMaxBookingsNum: _ctx.changeMaxBookingsNum
            }, null, 8, ["class", "ev", "alldayHeight", "fromType", "onChangeAvailableEvent", "onChangeIgnoreEvent", "onShowEventDetail", "onChangeMaxBookingsNum"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.optionalRemoveEvents)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionalRemoveEvents, (ev, evindex) => {
            return (_openBlock(), _createBlock(_component_OneEventContent, {
              key: `${evindex}${ev.systemUpdatedAt}${ev.start_time}${_ctx.date}${ev.type}${_ctx.optionalEvents.length}${ev.is_force}${ev.isSelecting}`,
              ev: ev,
              evindex: evindex,
              date: _ctx.date,
              class: _normalizeClass(`${_ctx.selecting ? `noClick` : ``}`),
              onDeleteOptionalEvent: _ctx.deleteOptionalEvent,
              onAddEvent: _ctx.addEvent,
              onStartDrag: _ctx.startDrag,
              onEndDrag: _ctx.endDrag
            }, null, 8, ["ev", "evindex", "date", "class", "onDeleteOptionalEvent", "onAddEvent", "onStartDrag", "onEndDrag"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.Util.isPresent(_ctx.onlineEvents))
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.onlineEvents, (ev, evindex) => {
            return (_openBlock(), _createBlock(_component_BlackBoxEvent, {
              key: `${evindex}${ev.start_time}${ev.systemUpdatedAt}`,
              class: _normalizeClass(`noClick`),
              ev: ev,
              provider: `zoom`,
              alldayHeight: _ctx.alldayHeight
            }, null, 8, ["ev", "alldayHeight"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.Util.isPresent(_ctx.possibleEvents))
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.possibleEvents, (ev, possibleindex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${ev.start_time}${possibleindex}${ev.max_bookings_num}`,
              class: _normalizeClass(`posAb rad5 w90 ofh ${_ctx.isSelfMode ? `` : `noClick`}`),
              style: _normalizeStyle({
                    height: `${(ev.duration / 15) * _ctx.CELL_HEIGHT - 3}px`,
                    top: `${(ev.start_hour * 4 + ev.start_min / 15) * _ctx.CELL_HEIGHT + _ctx.alldayHeight}px`,
                    left: `1%`,
                    border: `dotted 3px #ff8c72`,
                    background: `rgba(255,255,255,0.7)`,
                })
            }, [
              (_ctx.isSelfMode)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "posAb pointer z1",
                    style: _normalizeStyle({
                        top: ev.duration < 30 ? `-5px` : `5px`,
                        right: `2px`,
                    })
                  }, [
                    (ev.duration < 30)
                      ? _withDirectives((_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.$getAssetImageUrl(`/assets/popUp/close_dark_grey.png`),
                          width: "20",
                          height: "20",
                          onClick: ($event: any) => (_ctx.deleteOptionalEvent(ev))
                        }, null, 8, _hoisted_15)), [
                          [_directive_tooltip, `削除`]
                        ])
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: _ctx.$getAssetImageUrl(`/assets/popUp/close_dark_grey.png`),
                          width: "20",
                          height: "20",
                          onClick: ($event: any) => (_ctx.deleteOptionalEvent(ev))
                        }, null, 8, _hoisted_16))
                  ], 4))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                ((_ctx.rcm?.room?.max_bookings_num || 0) > 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "pad2 pointer",
                      onClick: ($event: any) => (_ctx.changeMaxBookingsNum(ev))
                    }, [
                      _createElementVNode("p", _hoisted_18, _toDisplayString(`0`) + "/" + _toDisplayString(ev.max_bookings_num || _ctx.rcm.room.max_bookings_num), 1)
                    ], 8, _hoisted_17))
                  : (_openBlock(), _createElementBlock("span", _hoisted_19, "提案候補"))
              ])
            ], 6))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}