
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"
import draggable from "vuedraggable"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import SelectFieldLi from "packs/pages/link/parts/room_settings/SelectFieldLi.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import UploadImageButton from "packs/components/buttons/UploadImageButton.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import FormFieldEditSettingOptionsContent from "packs/pages/link/parts/room_settings/room_settings_parts/FormFieldEditSettingOptionsContent.vue"

// モデルを読込.
import FormField from "packs/models/FormField"
import UserFile from "packs/models/UserFile"
import SelectUtil from "packs/utils/SelectUtil"
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        Switcher,
        SelectFieldLi,
        CheckboxTag,
        draggable,
        UploadImageButton,
        SelectTag,
        IconLock,
        IconHelp,
        FormFieldEditSettingOptionsContent,
    },
})
export default class FormFieldEditContent extends Vue {
    @Prop()
    editingField: FormField

    @Prop()
    fieldindex: number

    @Prop() // func
    showImageBoardModal

    field: FormField = null
    rm = RoomManager

    selectTypeIconsArray = [
        { field_type: `radio`, name: `ラジオボタン (単一選択)`, icon: `/assets/icons/field_radio.png`, checked: false },
        { field_type: `select`, name: `プルダウン`, icon: `/assets/icons/field_dropdown.png`, checked: false },
        {
            field_type: `checkbox`,
            name: `チェックボックス (複数選択)`,
            icon: `/assets/icons/field_checkbox.png`,
            checked: false,
        },
    ]
    selectWithImageTypeIconsArray = [
        { field_type: `radio_image`, name: `ラジオボタン (単一選択)`, icon: `/assets/icons/field_radio.png`, checked: false },
        {
            field_type: `checkbox_image`,
            name: `チェックボックス (複数選択)`,
            icon: `/assets/icons/field_checkbox.png`,
            checked: false,
        },
    ]

    value: string = ``
    selectableTypes: string[] = [`radio`, `select`, `checkbox`]
    selectableWithImagesTypes: string[] = [`radio_image`, `checkbox_image`]
    selSystemUpdatedAt = Util.getSec()
    drag: boolean = false
    imageEditIndex = null
    currentImageUrl = null
    displayTypes = SelectUtil.fieldDisplayTypes
    displayType = null
    rcm = RoomCreateManager
    titleErrorMessage = null

    mounted() {
        // $(`.textFieldInModal`).eq(0).focus()
        // this.textFieldInModal.focus()
        this.updateField()
    }

    getPlaceholderPlaceholder() {
        if (this.editingField.field_type.includes(`head`)) {
            return `見出し`
        } else if (this.editingField.field_type == `textarea`) {
            return `備考など`
        } else if (this.editingField.field_type == `input`) {
            return `メールアドレスなど`
        }
    }

    @Watch("editingField", { deep: true })
    updateField() {
        if (!this.editingField) return
        this.field = this.editingField
        let dic = this.selectTypeIconsArray.find(_t => _t.field_type == this.editingField.field_type)
        if (dic) {
            this.changeSelectType(dic)
        }
        dic = null
        this.displayType = this.field.display_type || `normal`

        dic = this.selectWithImageTypeIconsArray.find(_t => _t.field_type == this.editingField.field_type)
        if (dic) {
            this.changeSelectWithImageType(dic)
        }
    }

    inputNameValue(e) {
        this.field.field_name = e
        this.updateFieldFromChild()
        this.checkTitle()
    }

    checkTitle() {
        let name = this.field.field_name || ""
        if (name.includes(`"`)) {
            this.titleErrorMessage = `利用できない文字列(ダブルクォーテーション)が含まれています`
        } else {
            this.titleErrorMessage = null
        }
    }

    inputPlaceholderValue(e) {
        this.field.placeholder_attr = e
        this.updateFieldFromChild()
    }

    inputDescription(e) {
        this.field.description = e
        this.updateFieldFromChild()
    }

    changeRequiredFlg() {
        if (this.field.is_required) {
            this.field.is_required = false
        } else {
            this.field.is_required = true
        }
        this.updateFieldFromChild()
    }

    updateFieldFromChild(field: FormField) {
        field ||= this.field
        this.$emit(`updateFieldFromChild`, field)
    }

    inputSelValue(e: string, selindex: number) {
        let _selvals = this.field.selectable_values ? [...this.field.selectable_values] : []
        _selvals[selindex].value = e

        this.field.selectable_values = _selvals
        this.updateFieldFromChild()
        // this.field.selectable_values[selindex] = e
        // this.selSystemUpdatedAt = Util.getSec()
    }

    deleteSel(selindex: number) {
        this.field.selectable_values.splice(selindex, 1)
        this.selSystemUpdatedAt = Util.getSec()
        this.updateFieldFromChild()
    }

    // 画像選択するモーダルを表示します（indexを覚えておきます）
    uploadSelImage(selindex: number) {
        this.imageEditIndex = selindex
        if (this.field.field_type == `image1`) {
            this.currentImageUrl = this.field.value_attr
        } else {
            this.currentImageUrl = this.field.selectable_values[this.imageEditIndex].image_url
        }

        // 画像選択のモーダルを表示します.
        this.showImageBoardModal(this.currentImageUrl)
    }

    selectFile(image: UserFile) {
        // 画像を選択しました.
        if (this.field.field_type == `image1`) {
            let url = image.image_url
            this.field.value_attr = url
        } else {
            let _selvals = this.field.selectable_values ? [...this.field.selectable_values] : []
            _selvals[this.imageEditIndex].image_url = image.image_url
            this.field.selectable_values = _selvals
        }

        this.imageEditIndex = null
        this.updateFieldFromChild()
    }

    addSelectField() {
        let _selnum = (this.field.selectable_values || []).length
        let values = this.field.selectable_values || []
        values.push({ value: `選択肢${_selnum + 1}`, image_url: `` })

        this.field.selectable_values = values

        this.updateFieldFromChild()
    }

    clickFree() {
        if (this.field.allow_free_text) {
            this.field.allow_free_text = false
        } else {
            this.field.allow_free_text = true
        }
        this.updateFieldFromChild()
    }

    switchDisable() {
        if ([`attendee1_name`, `attendee1_email`].includes(this.field.name_attr)) {
            return true
        }
        return false
    }

    changeSelectType(typeDic) {
        this.field.field_type = typeDic.field_type
        for (let _typeDic of this.selectTypeIconsArray) {
            _typeDic.checked = false
            if (_typeDic.field_type == typeDic.field_type) {
                _typeDic.checked = true
            }
        }

        this.updateFieldFromChild()
    }

    changeSelectWithImageType(typeDic) {
        Logger(`changeSelectWithImageType: ${Util.output(typeDic)}`)
        this.field.field_type = typeDic.field_type
        for (let _typeDic of this.selectWithImageTypeIconsArray) {
            _typeDic.checked = false
            if (_typeDic.field_type == typeDic.field_type) {
                _typeDic.checked = true
            }
        }

        this.updateFieldFromChild()
    }

    sortEnd() {
        this.drag = false
        this.updateFieldFromChild()
    }

    selectDisplayType(e) {
        Logger(`FormFieldEditContent#selectDisplayType: ${e}`)
        if (!this.rm.canUseOption(`room_hidden_field_in_form`, false)) {
            this.field.display_type = `normal`
            return
        }
        let typeDic = this.displayTypes.find(d => d.name == e)
        if (typeDic) {
            this.field.display_type = typeDic.type
            this.updateFieldFromChild()
        }
    }

    toggleChangeLocationName() {
        if (this.field.change_location_name) {
            this.field.change_location_name = false
        } else {
            // 他にセットしている場合もあるので、いったんリセットします.
            for (let _f of this.rcm.fields) {
                _f.change_location_name = false
            }
            this.field.change_location_name = true
        }
        this.updateFieldFromChild()
    }

    changeBirthdateSpanType(type: string) {
        if (type == FormField.RELATIVE_TIME_RANGE) {
            Object.assign(this.field.option_hash, { [FormField.RELATIVE_TIME_RANGE]: true })
        } else {
            Object.assign(this.field.option_hash, { [FormField.ABSOLUTE_TIME_RANGE]: true })
        }
        this.updateFieldFromChild()
    }
}
