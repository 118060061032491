
import Util from "packs/utils/Util"
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({})
export default class IconGear extends Vue {
    @Prop() // アイコンの大きさ
    width: number

    @Prop() // 表示するテキスト
    tooltipText: string

    @Prop() // 詳しくはこちらのURL
    detailUrl: string

    @Prop() // grey / white
    color: string

    getColor() {
        if (Util.isBlank(this.color)) return `/assets/icons/gear_dblue.png`
        if (this.color == `grey`) return `/assets/icons/gear_dblue.png`
        if (this.color == `white`) return `/assets/icons/gear_dblue.png`
        return `/assets/icons/gear_dblue.png`
    }

    get getText() {
        if (!this.tooltipText) return null
        let text = this.tooltipText
        if (this.detailUrl) {
            text += `\n\n<a class='fw600 whiteColor' target='_blank' href='${this.detailUrl}'>詳しくはこちら</a>`
        }
        return text
    }
}
