
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { decodeQueryParam, funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import { gtagClick } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"

// コンポーネントを読込.
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import PPTosContent from "packs/components/forms/PPTosContent.vue"
import AddAttendeesForm from "packs/pages/schedule/AddAttendeesForm.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import FormField from "packs/models/FormField"
import AssignForm from "packs/models/AssignForm"

@Options({
    components: {
        FormFieldContent,
        PPTosContent,
        AddAttendeesForm,
        MdProgressSpinner,
    },
})
export default class FormContent extends Vue {
    @Prop()
    room: Room

    @Prop()
    currentStep: string

    @Prop()
    loadingFormSending: boolean

    @Prop()
    additionalParams

    @Prop()
    aform: AssignForm

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    pdm = PossibleDatesManager

    Util = Util

    fields: FormField[] = null
    loggedinFields: FormField[] = null
    loggedinStatus: string = null // loggedin / filled group_status:10000以上だと
    systemUpdatedAt = Util.getSec()
    hasInvalidFields: boolean = false
    // additionalParams = null
    setEnd = false

    displayAll = false
    autoSendForm = false

    // updatedFields: FormField[] = null

    created() {}

    mounted() {
        if (this.aform) {
            this.updateAssignForm()
        } else {
            this.updateRoom()
        }
    }

    showFormContent() {
        if (!this.setEnd) return false
        // フォームの入力が必要ない場合は表示しません.
        if (this.autoSendForm) return false

        return true
    }

    updateAssignForm() {
        if (!this.aform) return
        if (this.setEnd) return
        let _fields = this.aform.form_fields ? [...this.aform.form_fields] : []
        this.updateForm(_fields)
    }

    updateForm(_fields: FormField[]) {
        let rs = this.room?.room_setting

        let loggedinFields = []
        Logger(`${funcName()} userInfo:${Util.output(this.rm.userInfo)}, isResetFormForOwnerUg:${this.rm.isResetFormForOwnerUg}`)

        if (rs && rs.send_channel_type == `sms`) {
            Logger(`${funcName()} SMSのみ送信のため、メールアドレスの設問を外します.`)
            _fields = _fields.filter(f => f.param_key != `attendee1_email`)
            _fields = _fields.filter(f => f.param_key != `attendee1_cc_emails`)
        }

        if (this.rm.userInfo && !Util.isPreview() && !this.rm.isResetFormForOwnerUg) {
            loggedinFields = FormField.fillUserInfo(this.rm.userInfo, _fields, true)
        }

        // 回答済みのフィールドもloggedinFieldsに追加します.
        let answerdFields = _fields.filter(_f => _f.did_answer && _f.answered_value)
        if (answerdFields.length > 0 && !Util.isPreview() && !this.rm.isResetFormForOwnerUg) {
            for (let _field of answerdFields) {
                let _f = loggedinFields.find(_f => _f.param_key == _field.param_key)
                if (!_f) {
                    _field.isDisabled = true
                    if (
                        FormField.selectableTypes.includes(_field.field_type) ||
                        FormField.selectableWithImageTypes.includes(_field.field_type)
                    ) {
                        _field.selected_values = (_field.answered_value as string[]) || []
                    } else {
                        _field.value_attr = _field.answered_value
                    }

                    loggedinFields.push({ ..._field })
                }
                _field.value_attr = _field.answered_value
                _field.hideField = true
            }
        }

        Logger(`${funcName()} answerdFields: ${Util.output(answerdFields)}`)

        let hff = false

        // すでにパラメータを取得している場合、デフォルトの値として入力しておきます.
        if (Util.isPresent(this.additionalParams)) {
            let _hff = this.additionalParams.hide_filled_fields
            hff = Util.isPresent(_hff) && _hff == `true` ? true : false
            try {
                for (let _f of _fields) {
                    if (Util.isPresent(_f.value_attr)) continue

                    if (_f.param_key == `attendee1_name` && Util.isPresent(this.additionalParams[`name`])) {
                        _f.value_attr = decodeQueryParam(this.additionalParams[`name`])
                    } else if (_f.param_key == `attendee1_email` && Util.isPresent(this.additionalParams[`email`])) {
                        _f.value_attr = decodeQueryParam(this.additionalParams[`email`])
                    } else if (
                        _f.param_key == `attendee1_company_name` &&
                        Util.isPresent(this.additionalParams[`company_name`])
                    ) {
                        _f.value_attr = decodeQueryParam(this.additionalParams[`company_name`])
                    } else if (_f.param_key == `attendee1_tel` && Util.isPresent(this.additionalParams[`phone_number`])) {
                        _f.value_attr = decodeQueryParam(this.additionalParams[`phone_number`])
                    } else {
                        if (Util.isPresent(this.additionalParams[_f.param_key])) {
                            if (
                                FormField.selectableTypes.includes(_f.field_type) ||
                                FormField.selectableWithImageTypes.includes(_f.field_type)
                            ) {
                                let vals = decodeQueryParam(this.additionalParams[_f.param_key])
                                _f.selected_values = vals.split(`,`)
                            } else {
                                _f.value_attr = decodeQueryParam(this.additionalParams[_f.param_key])
                            }
                        }
                    }

                    if ((Util.isPresent(_f.value_attr) || Util.isPresent(_f.selected_values)) && hff) {
                        _f.hideField = true
                    }
                    for (let f of loggedinFields) {
                        if ((Util.isPresent(f.value_attr) || Util.isPresent(f.selected_values)) && hff) {
                            _f.hideField = true
                        }
                    }
                }
            } catch {}
        }
        // if (Util.isPreview()) {
        //     let ffs = []
        //     for (let _ff of _fields) {
        //         if (![`attendee1_name`, `attendee1_email`].includes(_ff.param_key)) {
        //             ffs.push(_ff)
        //         }
        //     }
        //     _fields = ffs
        // }

        this.loggedinFields = loggedinFields
        this.fields = _fields

        if (Util.isPresent(loggedinFields)) {
            if (this.rm.userInfo.group_status < 10000) {
                this.loggedinStatus = `filled`
            } else {
                this.loggedinStatus = `loggedin`
            }
        }

        let skipForm = this.additionalParams.skip_form
        if (Util.isPresent(skipForm) && skipForm == "true") {
            // すべてのフォーム入力が埋まっている場合に、フォームの表示を飛ばします。
            let notFilledFields = _fields.filter(_f => {
                if (FormField.selectableTypes.includes(_f.field_type)) {
                    if (Util.isBlank(_f.selected_values)) {
                        return _f
                    }
                } else {
                    if (Util.isBlank(_f.value_attr)) {
                        return _f
                    }
                }
            })
            Logger(`notFilledFields: ${notFilledFields.length}`)
            if (notFilledFields.length == 0) {
                Logger(`${funcName()} すべての項目が埋まっているため、フォームを飛ばします.`)
                // 上に通知.
                // this.send()
                this.$emit(`skipForm`, _fields)
                return
            }
        }

        let autoSendForm = this.additionalParams.auto_send_form
        if (Util.isPresent(autoSendForm) && autoSendForm == "true") {
            // すべての必須項目のフォーム入力が埋まっている場合に、自動で送信を行います。
            let notFilledRequiredFields = _fields.filter(_f => {
                if (!_f.is_required) return false

                if (FormField.selectableTypes.includes(_f.field_type)) {
                    if (Util.isBlank(_f.selected_values)) {
                        return _f
                    }
                } else {
                    if (Util.isBlank(_f.value_attr)) {
                        return _f
                    }
                }
            })
            Logger(`notFilledRequiredFields: ${notFilledRequiredFields.length}`)
            if (notFilledRequiredFields.length == 0) {
                Logger(`${funcName()} すべての必須項目が埋まっているため、フォームを飛ばします.`)
                // 上に通知.
                this.autoSendForm = true
                this.setEnd = true

                this.send()
                // this.$emit(`autoSendForm`, _fields)
                return
            }
        }

        this.setEnd = true
        Logger(`${funcName()} フィールドのバリューにはめました: ${Util.output(this.fields)}`)
    }

    @Watch("room", { deep: true })
    updateRoom() {
        if (!this.room) return
        if (this.setEnd) return
        let _fields = this.room.form_fields ? [...this.room.form_fields] : []
        this.updateForm(_fields)
        // this.updatedFields = this.room.form_fields ? [...this.room.form_fields] : []
    }

    get showTimezone() {
        if (!this.pdm.currentTZDic) return false
        let rs = this.room?.room_setting
        if (rs && !rs.can_change_timezone) {
            // timezoneのDicをリセットします.
            return false
        }
        return true
    }

    get isJpTimezone() {
        return this.pdm.isJpTimezone
    }

    updateFromChild(field: FormField) {
        Logger(`${funcName()} i.${field.index_num}. value_attr:${field.value_attr}, selected_values:${field.selected_values}`)
        let _fields = Util.isPresent(this.fields) ? [...this.fields] : []
        let _f = _fields.find(_f => _f.index_num == field.index_num)
        _f = Object.assign(_f, field)
        this.fields = _fields
    }

    resetMyForm() {
        if (!this.room.is_owner_group) return

        Logger(`${funcName()}`)
        this.rm.isResetFormForOwnerUg = true
        this.loggedinFields = []
        for (let _f of this.fields) {
            if (_f.is_special) {
                _f.hideField = false
                _f.value_attr = null
                _f.selected_values = []
            }
        }
    }

    blurField(field: FormField) {
        Logger(`${funcName()} i.${field.index_num}. isValid=${field.isValid}`)
        let _fields = Util.isPresent(this.fields) ? [...this.fields] : []
        let _f = _fields.find(_f => _f.index_num == field.index_num)
        let isValid = FormField.isValidField(_f)
        _f.isValid = isValid ? true : false
        this.fields = _fields
        // this.systemUpdatedAt = Util.getSec()
    }

    send() {
        Logger(`${funcName()} 送信時fields(${this.fields?.length}):${Util.output(this.fields)}`)
        let fields = [...this.fields]
        if (Util.isPresent(this.loggedinFields)) {
            // Array.prototype.push.apply(fields, this.loggedinFields)
            for (let _f of this.loggedinFields) {
                let f = fields.find(f => f.param_key == _f.param_key)
                if (f) {
                    let _fie = { ..._f }
                    _fie.hideField = true
                    Object.assign(f, _fie)
                }
            }
        }

        Logger(`${funcName()} fields(${fields?.length}):${Util.output(fields)}`)

        // validationを確認して、問題なければ親に通知
        let hasInvalid = false
        for (let _f of fields) {
            let isValid = FormField.isValidField(_f)
            if (isValid) {
                _f.isValid = true
            } else {
                hasInvalid = true
                _f.isValid = false
            }
        }
        if (hasInvalid) {
            // this.fields = this.updatedFields
            this.hasInvalidFields = true
            Logger(`${funcName()} フォームバリデーションで引っかかりました.`)
            // this.systemUpdatedAt = Util.getSec()
            let _ele = document.getElementById(`FormContent`)
            if (_ele) {
                _ele.scrollIntoView({
                    behavior: "smooth",
                })
            }
            return
        } else {
            this.hasInvalidFields = false
        }
        this.$emit(`completeForm`, fields)
    }

    changeDate() {
        if (this.loadingFormSending) return
        let str = this.pdm.currentPd ? this.pdm.currentPd.jp_format : this.pdm.currentPds.map(p => p.jp_format)
        gtagClick(`フォーム 別日程を選択`, `${str}`)
        this.$emit(`changeDate`)
    }

    @Watch(`rm.userInfo`, { deep: true })
    updateUserInfo() {
        if (Util.isBlank(this.rm.userInfo) || Util.isBlank(this.fields)) return
        if (this.setEnd) return
        Logger(`${funcName()} rm.userInfo:${Util.output(this.rm.userInfo)}`)

        // userInfoが更新されたときに、フィールドに入力不要な項目に自動的に保管しておきます。
        for (let _f of this.fields) {
            if (_f.param_key == `attendee1_name`) {
                _f.value_attr = this.rm.userInfo.name
            } else if (_f.param_key == `attendee1_email`) {
                _f.value_attr = this.rm.userInfo.email
            } else if (_f.param_key == `attendee1_company_name`) {
                _f.value_attr = this.rm.userInfo.company_name
            }
        }
    }

    hasColor() {
        if (Util.isPresent(this.aform?.form_submit_button_bg_color)) {
            return true
        }

        return false
    }

    buttonBgColor() {
        let styleDic = this.room || this.aform
        if (Util.isPresent(styleDic?.form_submit_button_bg_color)) {
            return `#${styleDic.form_submit_button_bg_color}`
        }

        return null
    }

    buttonTextColor() {
        let styleDic = this.room || this.aform
        if (Util.isPresent(styleDic?.form_submit_button_text_color)) {
            return `#${styleDic.form_submit_button_text_color}`
        }

        return null
    }

    fieldBeforeStyle() {
        return {
            marginTop: this.additionalParams?.field_margin_top ? `${this.additionalParams.field_margin_top}px` : `20px`,
        }
    }

    fieldBottomStyle() {
        return {
            marginBottom: this.additionalParams?.field_margin_bottom ? `${this.additionalParams.field_margin_bottom}px` : `15px`,
        }
    }

    submitButtonStyle() {
        return {
            width: `100%`,
            height: this.additionalParams?.submit_button_height ? `${this.additionalParams.submit_button_height}px` : `52px`,
            lineHeight: this.additionalParams?.submit_button_height ? `${this.additionalParams.submit_button_height}px` : `52px`,
            backgroundColor: this.buttonBgColor() || null,
            color: this.buttonTextColor() || null,
            fontSize: this.additionalParams?.submit_button_font_size
                ? `${this.additionalParams?.submit_button_font_size}px`
                : `16px`,
        }
    }

    submitButtonLoadingStyle() {
        return {
            width: `100%`,
            height: this.additionalParams?.submit_button_height ? `${this.additionalParams?.submit_button_height}px` : `52px`,
            lineHeight: this.additionalParams?.submit_button_height ? `${this.additionalParams?.submit_button_height}px` : `52px`,
            fontSize: this.additionalParams?.submit_button_font_size
                ? `${this.additionalParams?.submit_button_font_size}px`
                : `16px`,
        }
    }
}
