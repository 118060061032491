import { DateTime } from "luxon"
import AvailableScheduleTag from "./AvailableScheduleTag"
import Util from "packs/utils/Util"
import RoomMember from "./RoomMember"
import axios from "axios"
import NotificationControl from "packs/utils/NotificationControl"
import { Logger } from "packs/common"
import Notice from "packs/models/Notice"

export default class GroupTag {
    static readonly typeDisplay: number = 1

    constructor(
        public id: string, //MeetingGroup_idが入る可能性があるので、ctag_idをみたい場合は、calendar_tag_idを参照すること.
        public name: string,
        public description: string,
        public color: string,
        public show_type: string,
        public pinned_index_num: number,

        public systemUpdatedAt: number
    ) {}

    static copy(o: GroupTag): GroupTag {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        return clone
    }

    /**
     *
     * @param ctagsJson
     * @param from "mag"/"ctag" magで足らないパラメータ,ctagで足らないパラメータをそれぞれ埋める.
     */
    static fetchFromJson(ctagsJson: any, from: string = `ctag`): GroupTag[] {
        let tags = []
        if (!ctagsJson) return tags
        if (ctagsJson.length == 0) return tags

        for (let grtag of ctagsJson) {
            let _tag = this.createDefault()
            _tag.name = grtag.name
            _tag.color = grtag.color
            _tag.description = grtag.description
            _tag.id = grtag.id
            _tag.show_type = grtag.show_type
            _tag.pinned_index_num = grtag.pinned_index_num
            _tag.systemUpdatedAt = Util.getSec()
            tags.push(_tag)
        }
        return tags
    }

    static createDefault() {
        const _t = Util.getSec()
        const color = Util.getRandomColor()

        // const dic = {email: null, image_url: null, name: null} as OwnerInfo
        return new GroupTag(`newId:${_t}${color}`, null, null, color, null, 0, _t)
    }

    static getGroupTags(): Promise<GroupTag[]> {
        return axios
            .get(`${Util.prefixUrl}/user_settings/group_tags`)
            .then(res => {
                const _grtags = res.data.group_tags
                if (!_grtags) return []
                let grtags = GroupTag.fetchFromJson(_grtags)
                return grtags
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static upsertGroupTag(grTag: GroupTag): Promise<GroupTag> {
        if (grTag.id.includes(`newId:`)) grTag.id = null

        return axios
            .post(`${Util.prefixUrl}/user_settings/group_tags`, grTag)
            .then(res => {
                const _grtag = res.data.group_tag
                if (!_grtag) return null

                let grtag = GroupTag.fetchFromJson([_grtag])[0]
                let notice = Notice
                notice.message = `${res.data.message}`

                return grtag
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static deleteTag(grTag: GroupTag) {
        let params = { id: grTag.id }

        return axios
            .delete(`${Util.prefixUrl}/user_settings/group_tags`, { data: params })
            .then(res => {
                const _grtag = res.data.group_tag
                if (!_grtag) return null

                let grtag = GroupTag.fetchFromJson([_grtag])[0]
                Notice.message = `${res.data.message}`

                return grtag
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static createTabGroupTags(grTags: GroupTag[]) {
        let params = { group_tags: grTags }
        return axios
            .post(`${Util.prefixUrl}/user_settings/tab_group_tags`, params)
            .then(res => {
                const _grtags = res.data.group_tags
                if (!_grtags) return null

                let grtags = GroupTag.fetchFromJson(_grtags)
                Notice.message = `${res.data.message}`

                return grtags
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
