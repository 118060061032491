
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref, Emit } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import FormFieldCreator from "packs/utils/FormFieldCreator"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import RadioButtonTag from "packs/components/forms/RadioButtonTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import UserFile from "packs/models/UserFile"
import NotificationControl from "packs/utils/NotificationControl"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import FormField from "packs/models/FormField"
import Const from "packs/utils/Const"
import Notice from "packs/models/Notice"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomManager from "packs/models/RoomManager"
import axios from "axios"

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        SelectTag,
        RadioButtonTag,
        CheckboxTag,
        UserFileImage,
        MdProgressBar,
    },
    emits: ["blurField", "editField", "removeField", "copyField", "clickUp", "clickDown", "addNewField"],
})
export default class FormFieldContent extends Vue {
    @Prop()
    field: FormField

    @Prop()
    fieldindex: number

    @Prop()
    total: number

    @Prop()
    placeholder: string

    @Prop()
    editable: boolean

    @Prop() // その他のテキスト入力を許可しない
    hideFreeText: boolean

    @Prop() // func
    updateFromChild

    @Prop()
    additionalParams: any

    value: string = ``
    Util = Util
    FormFieldCreator = FormFieldCreator
    pdm = PossibleDatesManager
    rm = RoomManager

    selectOther = false

    isSP = isMobile(window.navigator).phone

    isDrag: string = null
    fileForm: any = null
    userFile: UserFile = null // メッセージに添付するファイル.
    imageSizeDic = this.isSP ? { width: `130px`, height: `130px` } : { width: `220px`, height: `220px;` }
    uploading = false

    // 住所の場合に利用
    pref = null
    address1 = null
    address2 = null
    birthdateOption = null

    mounted() {
        // $(`.textFieldInModal`).eq(0).focus()
        // this.textFieldInModal.focus()
        this.updateOptions()
    }

    updateOptions() {
        let settingOptions = this.field.option_hash?.field_setting_options
        if (settingOptions) {
            this.birthdateOption = settingOptions.find(op =>
                [FormField.RELATIVE_TIME_RANGE, FormField.ABSOLUTE_TIME_RANGE].includes(op.type)
            )
        } else {
            this.birthdateOption = null
        }
    }

    get showField() {
        if (!this.field) return false
        if (this.editable) return true
        if (this.field.display_type == `normal`) return true

        if (this.field.display_type == `same_ug_only`) {
            if (Util.isPreview()) return false
            // 同一ugの場合表示
            return this.pdm.room.is_owner_group ? true : false
        } else if (this.field.display_type == `same_ug_admin_only`) {
            if (Util.isPreview()) return false
            // 同一ugのadminの場合表示
            if (this.pdm.room.is_owner_group && this.rm.userInfo.user_group_role == 100) {
                return true
            }
            return false
        }
    }

    inputValue(e) {
        this.value = e
        // let _f = FormField.copy(this.field) // { ...this.field }
        let _f = { ...this.field }
        _f.value_attr = e
        this.updateFromChild(_f)
    }

    /**
     * その他で入力されたバリューを取得します。
     */
    inputOtherValue(e) {
        this.value = e
        let _f = { ...this.field }
        _f.value_attr = e
        this.updateFromChild(_f)
    }

    blurField() {
        let _f = { ...this.field }
        _f.value_attr = this.value
        this.$emit(`blurField`, _f)
    }

    cancelInput() {
        this.$vfm.close(`TextFieldModal`)
        this.$emit(`cancel`)
    }

    @Emit("editField")
    editField() {
        return this.field
    }

    @Emit("removeField")
    removeField() {
        return this.field
    }

    @Emit(`copyField`)
    copyField() {
        return this.field
    }

    @Emit("clickUp")
    clickUp() {
        return this.field
    }

    @Emit("clickDown")
    clickDown() {
        return this.field
    }

    @Emit("addNewField")
    addNewField() {
        return this.field.index_num
    }

    selectFieldValue(e) {
        Logger(`選択しました: ${e}`)
        let _f = FormField.copy(this.field)
        // let _f: FormField = { ...this.field }
        _f.selected_values = [e]
        Logger(`選択しました2: ${_f.selected_values}`)
        this.updateFromChild(_f)
        // this.selectField(this.field, e)
    }

    selectRadioValue(e) {
        Logger(`選択しました: ${e}`)
        let _f = FormField.copy(this.field)
        // let _f: FormField = { ...this.field }
        _f.selected_values = [e]
        Logger(`選択しました2: ${_f.selected_values}`)
        this.updateFromChild(_f)
    }

    clickCheckbox(field: FormField, selvalue: string) {
        Logger(`clickCheckbox:選択しました: ${selvalue}`)
        // let _f = { ...this.field }
        let _f = FormField.copy(this.field)
        let vals = _f.selected_values || []
        let val = vals.find(v => v == selvalue)
        if (val) {
            _f.selected_values = vals.filter(v => v != val)
        } else {
            vals.push(selvalue)
            _f.selected_values = vals
        }
        this.updateFromChild(_f)
    }

    clickOther() {
        Logger(`その他を入力`)
        if (this.field.field_type == `checkbox`) {
            this.clickCheckbox(this.field, `その他`)
        } else {
            this.selectRadioValue(`その他`)
            this.displayOtherField()
        }
    }

    /**
     * 年月日を入れるため、nullの場合は、配列を作成します.
     * [`2021`, `10`, `3`]
     */
    createDateArrayIfNull() {
        let _f = FormField.copy(this.field)
        if (Util.isBlank(_f.selected_values)) {
            _f.selected_values = Array(3)
        }
        return _f
    }

    selectFieldValueYear(e) {
        Logger(`selectFieldValueYear:選択しました: ${e}`)
        let _f = this.createDateArrayIfNull()
        _f.selected_values[0] = e
        Logger(`選択しました2: ${_f.selected_values}`)
        this.updateFromChild(_f)
    }

    selectFieldValueMonth(e) {
        Logger(`selectFieldValueMonth:選択しました: ${e}`)
        let _f = this.createDateArrayIfNull()
        _f.selected_values[1] = e
        Logger(`選択しました2: ${_f.selected_values}`)
        this.updateFromChild(_f)
    }

    selectFieldValueDay(e) {
        Logger(`selectFieldValueDay:選択しました: ${e}`)
        let _f = this.createDateArrayIfNull()
        _f.selected_values[2] = e
        Logger(`選択しました2: ${_f.selected_values}`)
        this.updateFromChild(_f)
    }

    displayOtherField() {
        Logger(`FormFieldContent#displayOtherField hideFreeText: ${this.hideFreeText}, param_key: ${Util.output(this.field)}`)
        if (this.hideFreeText) return false

        let _f = FormField.copy(this.field)
        if (_f.selected_values && _f.selected_values.includes(`その他`) && _f.allow_free_text) return true

        return false
    }

    checkDrag(event, key, status) {
        Logger(`event.dataTransfer.types: ${Util.output(event.dataTransfer.types)}`)
        this.isDrag = status ? key : null
        Logger(`isDrag: ${this.isDrag}`)
    }

    onDrop(event, key = "", image = {}) {
        Logger(`ドロップしました。`)

        if (this.uploading) {
            Notice.message = `アップロード中です。`
            return false
        }
        this.isDrag = null

        /**
         *
         */
        let paramsArray = UserFile.createFileParams(event, `chat`, true)

        // let params = UserFile.createFileParams(event)
        if (!paramsArray || paramsArray.length == 0) return

        let _params = paramsArray[0]

        // let parentId = this.getParentId()
        let pubId = this.pdm ? this.pdm.room.id : null
        _params.append(`public_room_id`, pubId)

        Logger(`送信するparams: ${Util.output(_params)}`)
        this.uploading = true

        UserFile.uploadFile(_params, `form`).then(data => {
            if (event) event.target.value = null
            // event.target.value = null
            event = null
            this.uploading = false

            if (data.result == `ok`) {
                let uf: UserFile = UserFile.fetchFromJson([data.file])[0]
                if (uf) {
                    this.userFile = uf
                    let _f = FormField.copy(this.field)
                    _f.value_attr = uf.file_id
                    _f.userFile = uf
                    this.updateFromChild(_f)
                }
            }
        })
    }

    deletedAttachment() {
        let _f = FormField.copy(this.field)
        _f.value_attr = null
        _f.userFile = null
        this.userFile = null
        this.updateFromChild(_f)
    }

    selectImage(selvalue: string) {
        Logger(`image選択しました: ${selvalue}`)
        // let _f = { ...this.field }
        let _f = FormField.copy(this.field)

        if (_f.field_type == `radio_image`) {
            _f.selected_values = [selvalue]
        } else {
            let val = (_f.selected_values || []).find(v => v == selvalue)
            if (val) {
                _f.selected_values = _f.selected_values.filter(v => v != val)
            } else {
                _f.selected_values.push(selvalue)
            }
        }

        this.updateFromChild(_f)
    }

    /**
     * 住所を入れるため、nullの場合は、配列を作成します.
     * [`1020072`, `東京都`, `千代田区飯田橋`, ``]
     */
    createAddressArrayIfNull() {
        let _f = FormField.copy(this.field)
        if (Util.isBlank(_f.selected_values)) {
            _f.selected_values = Array(4)
        }
        return _f
    }

    inputPostalCodeValue(e) {
        Logger(`${funcName()} val:${e}`)
        let _f = this.createAddressArrayIfNull()
        let postalCode = String(e)
        if (e.length != 7) return

        _f.selected_values[0] = postalCode
        let code = postalCode.slice(0, 3)
        axios.get(`/zip_code/${code}/${postalCode}.json`).then(res => {
            Logger(`${funcName()} res: ${Util.output(res)}`)
            if (Util.isPresent(res.data)) {
                let dic = res.data[0]
                this.pref = dic.prefecture_name
                _f.selected_values[1] = this.pref
                this.address1 = `${dic.city_name}${dic.town_name}`
                _f.selected_values[2] = this.address1
            }
            this.updateFromChild(_f)
        })
    }

    selectPrefec(e) {
        Logger(`${funcName()} val:${e}`)
        let _f = this.createAddressArrayIfNull()
        this.pref = e
        _f.selected_values[1] = this.pref
        this.updateFromChild(_f)
    }

    inputAddress1(e) {
        Logger(`${funcName()} val:${e}`)
        let _f = this.createAddressArrayIfNull()
        this.address1 = e
        _f.selected_values[2] = this.address1
        this.updateFromChild(_f)
    }

    inputAddress2(e) {
        Logger(`${funcName()} val:${e}`)
        let _f = this.createAddressArrayIfNull()
        this.address2 = e
        _f.selected_values[3] = this.address2
        this.updateFromChild(_f)
    }

    get birthdateYearOptions() {
        if (this.birthdateOption) {
            let { st, et } = FormField.birthdateStartToEnd(this.birthdateOption)
            return FormFieldCreator.selectYears(st.year, et.year)
        }
        return FormFieldCreator.selectDefaultYears
    }

    get birthdateStrictText() {
        if (this.birthdateOption && this.birthdateOption.restrict_date) {
            let { st, et } = FormField.birthdateStartToEnd(this.birthdateOption)
            return `※ ${st.year}年${st.month}月${st.day}日から${et.year}年${et.month}月${et.day}日までのみ入力可能`
        }
        return ``
    }

    fieldNameStyle() {
        return {
            fontSize: this.additionalParams?.field_name_font_size ? `${this.additionalParams?.field_name_font_size}px` : `18px`,
        }
    }

    fieldDescriptionStyle() {
        return {
            fontSize: this.additionalParams?.field_description_font_size
                ? `${this.additionalParams?.field_description_font_size}px`
                : `14px`,
        }
    }

    fieldInputStyle() {
        return {
            height: this.additionalParams?.field_input_height ? `${this.additionalParams?.field_input_height}px` : `50px`,
        }
    }

    textareaStyle() {
        return {
            borderRadius: `8px`,
            textIndent: 0,
            resize: `none`,
            lineHeight: `21px`,
            width: `100%`,
            height: this.additionalParams?.field_textarea_height ? `${this.additionalParams?.field_textarea_height}px` : `80px`,
        }
    }

    selectStyle() {
        return {
            minWidth: `100%`,
            height: this.additionalParams?.field_select_height ? `${this.additionalParams?.field_select_height}px` : `50px`,
        }
    }

    selectHeightStyle() {
        return this.additionalParams?.field_select_height ? `${this.additionalParams?.field_select_height - 10}px` : `40px`
    }
}
