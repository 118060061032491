
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import qs from "qs"
import { Logger, copyToClipboard, funcName } from "packs/common"
import Util from "packs/utils/Util"
import NotificationControl from "packs/utils/NotificationControl"
import { truncate } from "packs/models/TruncateUtil"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import IconStar from "packs/components/icons/IconStar.vue"
import PublicRoomStatusMenu from "packs/pages/link/parts/public_rooms/PublicRoomStatusMenu.vue"
import RoomCellEditMenu from "packs/pages/link/parts/RoomCellEditMenu.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import RoomTab from "packs/models/RoomTab"
import Notice from "packs/models/Notice"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Appointment from "packs/models/Appointment"
import AppealContent from "packs/models/AppealContent"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"

const roomStorage = new RoomStorage()

@Options({
    components: {
        AlertBadge,
        IconStar,
        PublicRoomStatusMenu,
        RoomCellEditMenu,
        UserIcon,
        CalendarIcon,
        CopyButton,
    },
})
export default class RoomCell extends Vue {
    @Prop()
    room: Room

    @Prop()
    showRoomModal //モーダルを表示するfunction

    @Prop() // func
    showInstructionDeleteModal

    @Prop()
    canDelete: boolean

    @Prop()
    isLastCell: boolean

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone
    selectedRoom = this.rm.currentRoom
    roomKeyId = null
    appo: Appointment = null
    apc: AppealContent = null
    // companyName = null;
    displayMember: RoomMember
    showTip = false
    showAppoTip = false
    showUrlTip = false
    Room = Room
    Util = Util
    title = ``
    truncate = truncate

    currentMemIndex = null
    isHoverPrivateMemo = false
    // members_with_links: RoomMember[] = [];
    // selectedTabStatus = this.roomStatusesHash.categoryProgress;

    mounted() {
        this.getRoomTitle()
    }

    bgColor(): string {
        if (!this.room) return this.isSP ? `skyblueBg` : `lskyblueBg`

        if (Room.isParentFixed(this.room.status)) return this.isSP ? `greenBg` : `lgreenBg`
        if (Room.isParentCanceled(this.room.status)) return this.isSP ? `alertRedBg` : `lalertRedBg`
        return this.isSP ? `skyblueBg` : `lskyblueBg`
    }

    get votedBgColor() {
        if (!this.room) return "333"
        let appo = this.room.current_appointment
        if (!this.appo || !this.appo.id) return "666"

        let colorStr = ""
        let hash = 0
        let appoId = appo.id

        for (let i = 0; i < appoId.length; i++) {
            hash = appoId.charCodeAt(i) + ((hash << 5) - hash)
        }
        let colour = ``
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xff
            colour += ("00" + value.toString(16)).substr(-2)
        }
        return colour
    }

    get showConnectInstantRoomIcon() {
        if (!this.room) return
        let rs = this.room.room_setting

        if (this.room.room_type == `public_room` && rs && rs.use_instant_room && rs.instant_room_id) {
            return true
        }
        return false
    }

    getRoomTitle() {
        if (!this.room) return

        let _ac = this.room.appeal_content
        this.appo = this.room.current_appointment
        if (_ac) {
            this.apc = _ac
            if (_ac && _ac.title) {
                this.title = _ac.title
                return
            }
        }

        if (this.appo && this.appo.name) {
            this.title = this.appo.name
        }
    }

    // @Emit("goRoom")
    goRoom(room: Room) {
        if (Room.isParentDraft(room.status)) return false
        if (room.room_type == `public_room`) {
            if ([`suspended`, `draft`].includes(room.public_status)) {
                return
            }
        }

        this.$emit("goRoom", room)
        // return room
    }

    goSummaryFixed(room: Room) {
        gtagClick(`pub今後の確定ページを確認`, `${room.id}`)
        this.$emit(`clickSummary`, room, `fixed`)
    }

    goSummaryNotFixed(room: Room) {
        gtagClick(`pub未確定ページを確認`, `${room.id}`)
        // roomStorage.saveSummaryTab(`notFixed`)
        // this.$router.push(`/public_summary`)
        this.$emit(`clickSummary`, room, `voted`)
    }

    clickSummary(room: Room) {
        this.$emit(`clickSummary`, room, `pv`)
    }

    clickVoted(room: Room) {
        gtagClick(`pub提案中`, `${this.room.rooms_voted_num}`)
        this.goSummaryNotFixed(room)
    }

    // @Emit("closeRoom")
    showCloseRoomModal() {
        // return room
        this.showRoomModal(this.room, "delete")
    }

    /**
     * メンバーのリンク情報を取得してきます. publicRoomの場合は、すでに手元にあるpage_urlとQRコードを表示します。
     */
    public getRoomMembersLink() {
        if (this.room.room_type == `public_room`) {
            Logger(`showPublicLink`)
            this.$emit(`showPublicLink`, this.room)
            return
        }
        this.rm.startProgress()
        const params = { id: this.room.id }
        // Getリクエストのパラムをオブジェクト化するオプション
        const paramsSerializer = qs.stringify(params)
        Logger(`get room_members_links from Remote::::`)
        axios
            .get(`${Util.prefixUrl}/rooms/room_members_links?${paramsSerializer}`)
            .then(result => {
                const _members: RoomMember[] = result.data.members
                Logger(`members with links: ${Util.output(_members)}`)
                let members: RoomMember[] = []
                if (Room.needOwner(this.room)) {
                    members = _members
                } else {
                    members = _members.filter(m => m.role != 100)
                }
                this.$emit("showMembersLinks", members, this.room)
                this.rm.endProgress()
                // this.members_with_links = members;
                // this.$vfm.open("RoomMembersLinkModal");
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                this.rm.endProgress()
                return null
            })
    }

    public copyPublicId() {
        $(this).select()
        copyToClipboard(this.room.public_id)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`publicIDをコピー`, `rid:${this.room.id}`)
    }

    public copyAppoText() {
        $(this).select()
        copyToClipboard(this.room.current_appointment.jp_format)
        this.showAppoTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`確定したアポ時間をコピー`, `rid:${this.room.id}`)
    }

    public copyPageUrl() {
        $(this).select()
        copyToClipboard(this.room.page_url)
        this.showUrlTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`公開ページのURLをコピー`, `rid:${this.room.id}`)
    }

    public hideTooltip(e) {
        this.showTip = false
        this.showAppoTip = false
        this.showUrlTip = false
    }

    /**
     * 入室済みの人数を返します。
     */
    public enterRoomNum(): number {
        let counter = 0
        let atts: RoomMember[] = (this.room.attendees || []).filter(att => {
            if (att.role == 100) return att
            if (Room.sharedCalendar(this.room, att)) {
                return att
            }
            if (this.room.progress_member_dic) {
                const histLarge = this.room.progress_member_dic[att.user_id]
                if (histLarge && histLarge.room.enter) return att
            }
        })
        return atts.length
        // if (Room.needOwner(this.room)) {
        //     return (
        //         this.room.invitations.filter(inv => inv.status >= 20).length + 1
        //     );
        // } else {
        //     return this.room.invitations.filter(inv => inv.status >= 20).length;
        // }
    }

    public totalNum(): number {
        if (!this.room) return 0
        return this.room.attendees.length
    }

    public roomLabels(): string {
        if (this.room.room_type == `public_room`) {
            let rs = this.room.room_setting
            if (rs && rs.is_instant_room) {
                return `label gradBlueBg`
            }
            return `label dblueBg`
        } else if (Room.isParentFixed(this.room.status)) {
            let rs = this.room.room_setting
            if (rs && rs.is_instant_room) {
                return `label gradBlueBg`
            }
            return "label label-green"
        } else if (this.room.status == Room.STATUS_ACTIVE_IN_PROGRESS) {
            return "fw600 ddgrey"
        }
    }

    get roomLabelName() {
        if (Util.isBlank(this.room)) return ``

        if (this.room.room_type == `public_room`) {
            let rs = this.room.room_setting
            if (rs && rs.is_instant_room) {
                return `スグINボタン`
            }

            return `公開ページ`
        } else if (Room.isParentFixed(this.room.status)) {
            let rs = this.room.room_setting
            if (rs && rs.is_instant_room) {
                return `スグIN`
            }
            return this.room.status_name
        } else if (this.room.status == Room.STATUS_ACTIVE_IN_PROGRESS) {
            return this.room.status_name
        }
    }

    /**
     * 子ルームを含めて、未読メッセージがないか検証します。
     * 未読がある場合、1を返す（バッジに数字を表示するわけでないので、これでOK）
     */
    get doesUnreads(): number {
        if (this.room.unread_num > 0) return 1
        if (Util.isBlank(this.room.children)) return 0
        if (this.room.children.find(c => c.unread_num > 0)) return 1
        return 0
    }

    public copyPage() {
        // 複製してセッティングページに遷移します。
        Logger(`copyPage`)
        gtagClick(`ルーム複製`, `pid:${this.room.id} ${this.room.members.length}人`)
        this.goRoomSettings(`copy`)
    }

    public editPage() {
        // 下書きから編集しに行きます。
        gtagClick(`下書きからルーム編集`, `pid:${this.room.id} ${this.room.members.length}人`)
        this.goRoomSettings(`edit`)
    }

    /**
     * 下書きにある調整ページを作成します。
     */
    public createRoom() {
        // astagがない場合、調整カレンダーページに遷移.
        if (this.rm.astag.id == `newId`) {
            // 先にastagを作成
            gtagClick("調整ページ作成 Astag未作成", `一覧 → AstagView`)
            Notice.message = `調整ページを作成するためには、調整カレンダーの作成を先にしてください。`
            this.$router.push("/available_schedule")
            return
        }
        // is_valid: falseの場合、作成できません。 → 編集画面へ
        if (!this.room.is_valid) {
            this.editPage()
            return
        }

        // 下書きから作成モーダルを表示させます。
        // this.$emit("createRoom", this.room)
        this.showRoomModal(this.room, "create")
    }

    // action: copy / edit
    public goRoomSettings(action: string) {
        let appo = this.room.current_appointment
        Logger(`${funcName()} action:${action} is_owner:${this.room.is_owner}, is_owner_ug:${this.room.is_owner_group}`)
        roomStorage.saveAction(action)
        let mems = [...(this.room.members || [])]
        if (this.room.room_type == `public_room`) {
            mems = []
        }

        // 共有権限を持っているか、オーナーの場合は、astagを取得して、公開ページを複製します。
        if (this.room.is_owner) {
            Logger(`${funcName()} is_shared_chukai:${this.room.is_shared_chukai}`)

            this.rm.getAstagDetail(this.room.owner_avaialble_schedule_tag_id).then(res => {
                if (res) {
                    let astag = res
                    this.rcm.setNew(this.room, appo, mems, astag, this.rm.userInfo, this.rm.user_setting, action)
                    roomStorage.saveRoomPreview(this.room)
                    roomStorage.saveAppointmentPreview(appo)

                    this.$router.push("/room_settings")
                }
            })
            return
        }

        // 自分のデフォルトのastagを利用して、公開ページを複製します。
        let astag = this.rm.astag
        if (astag.is_shared) {
            astag = this.rm.astags.find(ast => !ast.is_shared)
        }
        if (!astag) {
            Notice.message = `作成した調整カレンダーが見つかりませんでした`
            return
        }

        this.rcm.setNew(this.room, appo, mems, astag, this.rm.userInfo, this.rm.user_setting, action)
        roomStorage.saveRoomPreview(this.room)
        roomStorage.saveAppointmentPreview(appo)

        this.$router.push("/room_settings")
    }

    // モーダル表示ボタンの表示/非表示
    public showHideCloseRoomModalButton(): boolean {
        const role = this.selectedRoom ? this.selectedRoom.role : 0 || 0
        if (
            this.selectedRoom &&
            this.selectedRoom.isParent &&
            Room.isParentActiveWithoutCanceled(this.selectedRoom.status) &&
            role == 1
        ) {
            return true
        } else {
            return false
        }
    }

    public fadeOutCloseRoom() {
        if (!this.rm.currentRoom && this.rm.openRooms) {
            this.rm.updateCurrentInfo(this.rm.openRooms[0])
        }
    }

    public selectCell(room) {
        $("tr").removeClass("selected")

        const roomKey = room.keyId
        $(`.${roomKey}`).addClass("selected")

        // 最後に参照したルームIDを保存しておきます。
        this.roomKeyId = roomKey
        Logger(`room key: ${this.roomKeyId} id: ${room.id}`)
        roomStorage.saveSelectedRoomKey(this.roomKeyId)

        // current系を最新の情報にアップデートします。
        RoomManager.updateCurrentInfo(room)
    }

    get checkStarred() {
        if (!this.room) return false

        return this.room.starred
    }

    public pushStar() {
        let action = ``
        if (this.room.starred) {
            // はずす
            action = `remove`
        } else {
            // スター
            action = `add`
        }
        gtagClick(`fav room`, `${action}`)
        this.rm.startProgress()
        Room.saveFav(this.room.id, action, this.room.room_type).then(e => {
            this.rm.endProgress()
            if (!e) return
            if (action == `add`) {
                this.room.starred = true
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms.push(this.room)
                }
            } else {
                this.room.starred = false
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms = favtab.rooms.filter(r => r.id != this.room.id)
                }
            }
            this.rm.updateFav(this.room)
        })
    }

    /**
     * 公開ページの公開ステータスを変更します.
     * @param status [string] 変更したいステータス = open, suspend, delete
     */
    public changeStatus(status: string) {
        Logger(`${funcName()} status:${this.room.public_status}, name:${this.room.public_status_name}  -> ${status}`)
        //
        if (status == `delete`) {
            if ([`suspended`, `draft`].includes(this.room.public_status)) {
                this.showRoomModal(this.room, "delete")
            } else {
                // 削除するためには停止中に変更してください。のモーダルを表示します.
                this.showInstructionDeleteModal()
            }
        } else {
            Room.changeStatus(this.room, status).then(isSuccess => {
                if (isSuccess) {
                    this.room.public_status_name = {
                        draft: `下書き`,
                        publishing: `公開中`,
                        suspended: `公開停止中`,
                        hidden: `削除済み`,
                    }[status]
                    this.room.public_status = status
                }
            })
        }
    }

    get hasImage(): boolean {
        if (!this.apc) return false
        return this.apc.image_url ? true : false
    }

    // 投票形式で確定した予定をキャンセルします。（モーダルを表示）
    cancelVoted(room: Room) {
        this.$emit(`cancelVoted`, room)
    }

    cancelAppo(room: Room) {
        this.$emit(`cancelAppo`, room)
    }

    createFeedback(room: Room) {
        this.$emit(`createFeedback`, room)
    }

    clickEditMenu() {
        Logger(`${funcName()} isLastCell:${this.isLastCell}`)
        gtagClick(`roomCell`, `EditMenu: isLast:${this.isLastCell}`)
        if (this.isLastCell) {
            // 一番したのセルだったら場所をずらします.
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "-110px" })
        } else {
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "auto" })
        }
    }

    // 終了したページを戻します.
    changeActive(room: Room) {
        this.$emit(`changeActive`, room)
    }

    get selectedMagTeamName() {
        if (!this.appo || !this.appo.selected_mag_dic) return
        let dic = Object.values(this.appo.selected_mag_dic)[0]
        if (!dic) return null
        // team_nameは自分で設定した名前、なければ name: A, Bで選択されたものを表示.
        let name = dic.team_name || dic.name
        return name || null
    }

    searchByMember(mem: RoomMember) {
        gtagClick(`セル検索 メンバー`, `${mem.user_id}`)
        this.$emit(`searchByMember`, mem)
    }

    mouseoverMember(mem: RoomMember, memindex: number) {
        Logger(`mouseoverMember: mem: ${mem.email}, memindex: ${memindex}`)
        if (Const.chukaiFreePlan(this.rm)) return
        this.currentMemIndex = memindex
    }

    mouseleaveMember() {
        this.currentMemIndex = null
    }

    searchByMag() {
        let mag: MeetingAttendeesGroup = Object.values(this.appo.selected_mag_dic)[0]
        gtagClick(`セル検索 担当`, `${mag.team_name}`)
        this.$emit(`searchByMag`, mag)
    }

    clickFormMember(mem: RoomMember) {
        // フォーム確認
        this.$emit(`clickFormMember`, mem, this.room)
    }

    editPrivateMemo() {
        this.$emit(`editPrivateMemo`, this.room)
    }

    mouseoverPrivateMemo() {
        this.isHoverPrivateMemo = true
    }

    mouseleavePrivateMemo() {
        this.isHoverPrivateMemo = false
    }

    showShareAuthModal(room: Room) {
        // オーナー権限を付与するモーダルを表示.
        this.$emit(`showShareAuthModal`, room)
    }

    get boardUrl() {
        if (!this.room) return
        let url = this.room.page_url
        return (url || ``).replace(`/p/`, `/p/board/`)
    }

    copyBoardUrl() {
        $(this).select()
        copyToClipboard(this.boardUrl)
        this.showUrlTip = true
        setTimeout(this.hideTooltip, 2000)
    }
}
