import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.currentPd)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`${_ctx.isSP ? `` : `pad10`} rad8 mc posRe`),
        style: _normalizeStyle({
            width: _ctx.isSP ? `` : `340px`,
            height: `70px`,
        })
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(`${_ctx.isSP ? `fs16` : `fs20`} warningText  arial centered fw600`),
          style: _normalizeStyle({
                paddingTop: _ctx.isSP ? `5px` : ``,
                marginTop: `13px`,
            })
        }, _toDisplayString(_ctx.currentPd.jp_format), 7),
        (_ctx.pdIndexType != `first`)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "posAb pointer",
              style: _normalizeStyle({
                top: _ctx.isSP ? `0px` : `20px`,
                left: `0`,
            }),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showBeforePossibleDate && _ctx.showBeforePossibleDate(...args)))
            }, [
              _createElementVNode("img", {
                src: _ctx.$getAssetImageUrl(`/assets/icons/arrow_left.png`),
                width: "27",
                height: "30"
              }, null, 8, _hoisted_1)
            ], 4))
          : _createCommentVNode("", true),
        (_ctx.pdIndexType != `last`)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "posAb pointer",
              style: _normalizeStyle({
                top: _ctx.isSP ? `0px` : `20px`,
                right: `0`,
            }),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showAfterPossibleDate && _ctx.showAfterPossibleDate(...args)))
            }, [
              _createElementVNode("img", {
                src: _ctx.$getAssetImageUrl(`/assets/icons/arrow_right.png`),
                width: "27",
                height: "30"
              }, null, 8, _hoisted_2)
            ], 4))
          : _createCommentVNode("", true)
      ], 6))
    : _createCommentVNode("", true)
}