import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.mem && _ctx.myPd)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`centered w100 posRe ${_ctx.myPd?.conflict_info?.is_conflict ? `lgreyBg` : ``}`),
        style: {"height":"100%"}
      }, [
        _createElementVNode("div", null, [
          (_ctx.myPd.conflict_info ? _ctx.myPd.conflict_info.is_conflict : false)
            ? _withDirectives((_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.$getAssetImageUrl(`/assets/icons/bikkuri_yellow.png`),
                width: "24",
                class: "mt15"
              }, null, 8, _hoisted_1)), [
                [_directive_tooltip, `${_ctx.mem.name}様のカレンダーで確定した予定があります`]
              ])
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _ctx.$getAssetImageUrl(`/assets/icons/circle_bold.png`),
                width: "24",
                class: "mt15"
              }, null, 8, _hoisted_2))
        ])
      ], 2))
    : _createCommentVNode("", true)
}