import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import AppealContent from "packs/models/AppealContent"
import FormField from "packs/models/FormField"
import { funcName, Logger } from "packs/common"
import NotificationControl from "packs/utils/NotificationControl"
import RoomMember from "packs/models/RoomMember"

/**
 * 振り分け先を指定します.
 */
export default class AnswerCondition {
    static readonly TYPE = {
        equal: "equal", // 同じ
        not_equal: "not_equal", // 同じでない
        contains: "contains", // 含む
        not_contains: "not_contains", // 含まない
        starts_with: "starts_with", // で始まる
        is_not_empty: "is_not_empty", // 空でない
        is_empty: "is_empty", // 空
    }
    static readonly TYPE_JP = {
        equal: "以下と同じ", // 同じ
        not_equal: "以下と同じでない", // 同じでない
        contains: "以下を含む", // 含む
        not_contains: "以下を含まない", // 含まない
        starts_with: "以下で始まる", // で始まる
        is_not_empty: "空でない", // 空でない
        is_empty: "空", // 空
    }
    static readonly SELECT_TYPE_JP = {
        equal: "以下と同じ", // 同じ
        not_equal: "以下と同じでない", // 同じでない
        contains: "以下のいずれかを含む", // 含む
        not_contains: "以下のいずれも含まない", // 含まない
    }
    constructor(
        public id: string,
        public public_id: string,
        public field_public_id: string,
        public compare_type: string,
        public text_value_arr: string[]
    ) {}

    static readonly TEXT_TYPES = ["equal", "not_equal", "contains", "not_contains", "starts_with", "is_not_empty", "is_empty"]
    static readonly SELECT_TYPES = ["equal", "not_equal", "contains", "not_contains"]

    static createDefault() {
        let pubId = Util.getRandomPublicId(`ANC`)
        return new AnswerCondition(null, pubId, null, null, null)
    }

    static isTextType(fieldType: string) {
        Logger(`AnswerCondition.isTextType fieldType:${fieldType}`)
        /**
         * INPUT = :input
        INPUT_EMAIL = :input_email
        INPUT_URL = :input_url
        TEXTAREA = :textarea
        ADDRESS = :address


        RADIO = :radio
        SELECT = :select
        CHECKBOX = :checkbox
        BIRTHDATE = :birthdate
        RADIO_IMAGE = :radio_image # 画像選択 (単一選択)
        CHECKBOX_IMAGE = :checkbox_image # 画像選択 (複数選択)
        UPLOAD_FILE = :upload_file
         */
        return ["input", "input_email", "input_url", "textarea", "address"].includes(fieldType)
    }
}
