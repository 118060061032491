
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import Notice from "packs/models/Notice"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import AssignForm from "packs/models/AssignForm"

@Options({
    components: {},
})
export default class PPTosContent extends Vue {
    @Prop()
    room: Room

    @Prop()
    aform: AssignForm

    Util = Util
    rm = RoomManager

    // updatedFields: FormField[] = null

    created() {}

    mounted() {}

    goPPUrl() {
        let url = this.aform ? Util.getUrl(this.aform.privacy_policy_url) : Util.getUrl(this.room.room_setting.privacy_policy_url)
        gtagClick(`他社PP`, url)
        // window.open(url, "_blank")
    }

    goTosUrl() {
        let url = this.aform
            ? Util.getUrl(this.aform.terms_of_service_url)
            : Util.getUrl(this.room.room_setting.terms_of_service_url)
        gtagClick(`他社TOS`, url)
        // window.open(url, "_blank")
    }

    hasTos() {
        return Util.isUrl(this.room?.room_setting?.terms_of_service_url) || Util.isUrl(this.aform?.terms_of_service_url)
    }

    hasPP() {
        return Util.isUrl(this.room?.room_setting?.privacy_policy_url) || Util.isUrl(this.aform?.privacy_policy_url)
    }

    tosName() {
        if (this.aform) {
            return this.aform.terms_of_service_name || `利用規約`
        }
        return this.room?.terms_of_service_name || `利用規約`
    }

    ppName() {
        if (this.aform) {
            return this.aform.privacy_policy_name || `プライバシーポリシー`
        }
        return this.room?.privacy_policy_name || `プライバシーポリシー`
    }

    showWaaqTos() {
        if (this.aform) {
            return this.aform.show_waaq_tos
        }
        return this.room?.show_waaq_tos
    }

    noShowAny() {
        return !this.showWaaqTos() && !this.hasTos() && !this.hasPP()
    }
}
